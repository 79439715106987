import { create } from 'zustand';
import { defaultDates } from '../common';

// interface OperationHours {
//   category: string;
//   europe: number;
//   asia: number;
//   lamerica: number;
//   meast: number;
//   total: number;
// }

interface CoverageByOperators {
  category: string;
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  total: number;
}

interface SavingsData {
  category: string;
  value: number;
}

interface OperationalHours {
  hours: number;
  minutes: number;
}

// define types for state values and actions separately
type State = {
  operationalHours: {
    operations: OperationalHours;
    operators: OperationalHours;
  };
  coverage: { operations: string; operators: string };
  totalSavings: string;
  co2Savings: string;
  operationalTypesEfficiency: any;
  includeWeekends: boolean;
  operationalHoursByOperations: any[];
  coverageByOperations: any[];
  operationalHoursByOperators: any[];
  coverageByOperators: any[];
  savingsData: SavingsData[];
  completedWork: any[];
  dateRange: any[];
  view: string;
  fromView: string;
  selectedDate: any;
  selectedTractorId: number;
  driveActionsList: Map<number, any>;
};

type Actions = {
  addOperationalHours: (
    operations: OperationalHours,
    operators: OperationalHours,
  ) => void;
  addCoverage: (coverage: { operations: string; operators: string }) => void;
  addTotalSavings: (savings: string) => void;
  addCo2Savings: (savings: string) => void;
  addOperationalTypesEfficiency: (operationalTypesEfficiency: any) => void;
  toggleWeekends: (state: boolean) => void;
  addOperationalHoursByOperations: (
    operationalHoursByOperations: any[],
  ) => void;
  addCoverageByOperations: (coverageByOperators: CoverageByOperators[]) => void;
  addOperationalHoursByOperators: (operationalHoursByOperators: any[]) => void;
  addCoverageByOperators: (coverageByOperators: CoverageByOperators[]) => void;
  addSavingsData: (savingsData: SavingsData[]) => void;
  addCompletedWorkData: (completedWork: any[]) => void;
  setDateRange: (dateRange: any[]) => void;
  setView: (view: string) => void;
  setFromView: (view: string) => void;
  setSelectedDate: (date: any) => void;
  reset: () => void;
  setDriveActionsList: (driveActionsList: Map<number, any>) => void;
};

// const efficiencyItemData = {
//   acer: 0,
//   percentage: 0,
//   operators: [],
// };

// define the initial state
const initialState: State = {
  operationalHours: {
    operations: {
      hours: 0,
      minutes: 0,
    },
    operators: {
      hours: 0,
      minutes: 0,
    },
  },
  coverage: {
    operations: '0',
    operators: '0',
  },
  operationalHoursByOperations: [],
  coverageByOperations: [],
  operationalHoursByOperators: [],
  coverageByOperators: [],
  totalSavings: '0',
  co2Savings: '0',
  operationalTypesEfficiency: {},
  includeWeekends: false,
  savingsData: [],
  completedWork: [],
  dateRange: defaultDates,
  view: 'default',
  fromView: 'default',
  selectedDate: '',
  selectedTractorId: 0,
  driveActionsList: new Map(),
};

const useStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  addOperationalHours: (operations, operators) =>
    set((state) => ({
      operationalHours: {
        operations,
        operators,
      },
    })),
  addCoverage: ({ operators, operations }) =>
    set((state) => ({
      coverage: {
        operations,
        operators,
      },
    })),
  addTotalSavings: (totalSavings) =>
    set((state) => ({
      totalSavings,
    })),
  addCo2Savings: (co2Savings) =>
    set((state) => ({
      co2Savings,
    })),
  addOperationalTypesEfficiency: (operationalTypesEfficiency) =>
    set((state) => ({
      operationalTypesEfficiency,
    })),
  toggleWeekends: () =>
    set((state) => ({
      includeWeekends: !state.includeWeekends,
    })),
  addOperationalHoursByOperations: (operationalHoursByOperations) =>
    set((state) => ({
      operationalHoursByOperations,
    })),
  addCoverageByOperations: (coverageByOperations) =>
    set((state) => ({
      coverageByOperations,
    })),
  addOperationalHoursByOperators: (operationalHoursByOperators) =>
    set((state) => ({
      operationalHoursByOperators,
    })),
  addCoverageByOperators: (coverageByOperators) =>
    set((state) => ({
      coverageByOperators,
    })),
  addSavingsData: (savingsData) =>
    set((state) => ({
      savingsData,
    })),
  addCompletedWorkData: (completedWork) =>
    set((state) => ({
      completedWork,
    })),
  setDateRange: (dateRange) =>
    set((state) => ({
      dateRange,
    })),
  setView: (view) =>
    set((state) => ({
      view,
    })),
  setFromView: (fromView) =>
    set((state) => ({
      fromView,
    })),
  setSelectedDate: (selectedDate) =>
    set((state) => ({
      selectedDate,
    })),
  setDriveActionsList: (driveActionsList) =>
    set((state) => ({
      driveActionsList,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default useStore;
