import { notification } from 'antd';
import useStore from '../store/operationAnalytics';
import {
  getEfficiencyData,
  getEnerySavingCostData,
  getOperationalHoursAndCoverage,
} from '../services/api';
import { useContext, useMemo, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import moment from 'moment';
import {
  fetchName,
  getOperationData,
  getTotalHours,
  getValue,
} from '../common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAnalytics = () => {
  const [efficiencyLoader, setEfficiencyLoader] = useState(false);
  const [totalSavingsLoader, setTotalSavingsLoader] = useState(false);
  const [operationsLoader, setOperationsLoader] = useState(false);
  const {
    addCo2Savings,
    addCoverage,
    addOperationalHours,
    addTotalSavings,
    addOperationalTypesEfficiency,
    addOperationalHoursByOperations,
    addOperationalHoursByOperators,
    addCoverageByOperators,
    addCoverageByOperations,
    addSavingsData,
    dateRange,
    selectedDate,
  } = useStore();
  const { userDetails } = useContext(ApplicationContext);

  const dateRangeText = useMemo(() => {
    if (dateRange && !dateRange.length) return '';
    const [fromDate, toDate] = dateRange;
    const diffMonth =
      fromDate?.toDate().getMonth() !== toDate?.toDate().getMonth();
    return (
      moment(fromDate).format('MMMM Do') +
      ' - ' +
      (diffMonth
        ? moment(toDate).format('MMMM Do')
        : moment(toDate).format('Do'))
    );
  }, [dateRange]);

  const dateText = useMemo(() => {
    if (!selectedDate) return '';
    return moment(selectedDate).format('MMMM Do');
  }, [selectedDate]);

  const initOperationalHoursAndCoverageData = async (
    from_date: string,
    to_date: string,
    imp_type?: string,
  ) => {
    try {
      setOperationsLoader(true);
      const response = await getOperationalHoursAndCoverage(
        from_date,
        to_date,
        imp_type,
      );
      if (response?.coverage_and_op_hours_imp) {
        const { coverage_and_op_hours_imp } = response;
        const operationalHours = coverage_and_op_hours_imp.map((item: any) =>
          Object.assign({
            operator_id: item.operator_id,
            category: fetchName(
              item?.user_first_name,
              item?.user_last_name,
              true,
            ),
            total: getTotalHours(item?.total_operational_hours),
            value1: getValue(item?.manual_hours),
            value2: getValue(item?.operator_assist_hours),
            value3: getValue(item?.autodrive_hours),
            value4: getValue(item?.ideal_hours),
            time1: getTotalHours(item?.manual_hours),
            time2: getTotalHours(item?.operator_assist_hours),
            time3: getTotalHours(item?.autodrive_hours),
            time4: getTotalHours(item?.ideal_hours),
          }),
        );
        const coverage = coverage_and_op_hours_imp.map((item: any) =>
          Object.assign({
            operator_id: item.operator_id,
            category: fetchName(
              item?.user_first_name,
              item?.user_last_name,
              true,
            ),
            total: item?.total_covered_area_in_acre,
            value1: item?.total_covered_area_in_acre,
          }),
        );
        // Determine if all values are less than 1 (i.e., minutes)
        const allValuesAreMinutes = operationalHours.every((item: any) =>
          ['value1', 'value2', 'value3', 'value4'].every(
            (value) => item[value] < 1,
          ),
        );
        const processedData = getOperationData(
          operationalHours,
          allValuesAreMinutes,
        );

        addOperationalHoursByOperators(processedData);
        addCoverageByOperators(coverage);
      }
      if (response?.op_hours_and_cov_for_imp) {
        const { op_hours_and_cov_for_imp } = response;
        const operationalHours = op_hours_and_cov_for_imp
          .map((item: any, key: string) => {
            const label = Object.keys(item)[0];
            const dataValue = item[Object.keys(item)[0]];
            return Object.assign({
              category: label,
              total: getTotalHours(dataValue[0]?.total_operational_hours),
              value1: getValue(dataValue[0]?.manual_hours),
              value2: getValue(dataValue[0]?.operator_assist_hours),
              value3: getValue(dataValue[0]?.autodrive_hours),
              value4: getValue(dataValue[0]?.ideal_hours),
              time1: getTotalHours(dataValue[0]?.manual_hours),
              time2: getTotalHours(dataValue[0]?.operator_assist_hours),
              time3: getTotalHours(dataValue[0]?.autodrive_hours),
              time4: getTotalHours(dataValue[0]?.ideal_hours),
            });
          })
          .sort((a: any, b: any) => a.category.localeCompare(b.category));
        const coverage = op_hours_and_cov_for_imp.map(
          (item: any, key: string) => {
            const label = Object.keys(item)[0];
            const dataValue = item[Object.keys(item)[0]];
            return Object.assign({
              category: label,
              total: dataValue[0]?.total_covered_area_in_acre,
              value1: dataValue[0]?.total_covered_area_in_acre,
            });
          },
        );
        // Determine if all values are less than 1 (i.e., minutes)
        const allValuesAreMinutes = operationalHours.every((item: any) =>
          ['value1', 'value2', 'value3', 'value4'].every(
            (value) => item[value] < 1,
          ),
        );
        const processedData = getOperationData(
          operationalHours,
          allValuesAreMinutes,
        );

        addOperationalHoursByOperations(processedData);
        addCoverageByOperations(coverage);
      }
      if (
        response?.summary_op_hours_and_cov_for_imp &&
        response?.summary_of_coverage_and_op_hours_imp
      ) {
        const { combined_covered_area, combined_operational_hours } =
          response.summary_of_coverage_and_op_hours_imp;
        const { combined_total_hours, combined_total_area } =
          response.summary_op_hours_and_cov_for_imp;

        const operations = {
          hours: combined_total_hours?.split(':')?.[0],
          minutes: combined_total_hours?.split(':')?.[1],
        };
        const operators = {
          hours: combined_operational_hours?.split(':')?.[0],
          minutes: combined_operational_hours?.split(':')?.[1],
        };
        const coverage = {
          operator: combined_covered_area,
          operations: combined_total_area,
        };
        addOperationalHours(operations, operators);
        addCoverage({
          operations: coverage?.operations,
          operators: coverage?.operator,
        });
      }
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setOperationsLoader(false);
    }
  };

  const initEfficiencyData = async (
    operationType = '',
    fromDate: string,
    toDate: string,
  ) => {
    try {
      setEfficiencyLoader(true);
      addOperationalTypesEfficiency([]);
      if (!userDetails?.organization) return false;
      const data = await getEfficiencyData(operationType, fromDate, toDate);
      let { acres_efficiency } = data;
      acres_efficiency = Object.keys(acres_efficiency)
        .sort((a, b) => a.localeCompare(b))
        .reduce((a: any, key: string) => {
          a[key] = acres_efficiency[key];
          return a;
        }, {});
      addOperationalTypesEfficiency(acres_efficiency);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setEfficiencyLoader(false);
    }
  };

  const initEnergySavingCostData = async (fromDate: string, toDate: string) => {
    try {
      setTotalSavingsLoader(true);
      const data = await getEnerySavingCostData(fromDate, toDate);
      const { energy_saving_data, total_saving, total_co2_saved } = data;
      const energySavingData = energy_saving_data.map((item: any) => ({
        category: item.date,
        value: item.saving,
      }));
      addSavingsData(energySavingData);
      addCo2Savings(total_co2_saved);
      addTotalSavings(total_saving);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setTotalSavingsLoader(false);
    }
  };

  return {
    initEfficiencyData,
    initEnergySavingCostData,
    initOperationalHoursAndCoverageData,
    dateRangeText,
    dateText,
    efficiencyLoader,
    operationsLoader,
    totalSavingsLoader,
  };
};

export default useAnalytics;
