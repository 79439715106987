import { Space, Button } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  handleOnChange: (dates: any) => void;
  selectedDate?: any[];
}

const CDateRangePicker: React.FC<Props> = ({
  handleOnChange,
  selectedDate,
}) => {
  const [toggle, setToggle] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (end) handleOnChange([moment(start), moment(end)]);
  };

  const handleSumbit = () => {
    if (endDate) {
      handleOnChange([moment(startDate), moment(endDate)]);
      togglePicker();
    }
  };

  const togglePicker = () => {
    setToggle(!toggle);
  };

  const handleClick = (dates: any) => {
    setStartDate(dates[0].toDate());
    setEndDate(dates[1].toDate());
    handleOnChange(dates);
    togglePicker();
  };

  const disabledDate = (current: any) => {
    return endDate === null
      ? current < moment(startDate).add(1, 'month').toDate()
      : true;
    // return current < moment(startDate).endOf('month').toDate();
  };

  useEffect(() => {
    if (selectedDate?.length) {
      setStartDate(selectedDate[0].toDate());
      setEndDate(selectedDate[1].toDate());
    }
  }, [selectedDate]);

  return (
    <div className="date-picker-container">
      <div className={toggle ? 'dhover date-picker' : 'date-picker'}>
        <div
          className={toggle ? 'dpIconhover' : 'dpIcon'}
          onClick={togglePicker}
        ></div>
      </div>
      {toggle && (
        <div className="date-picker-block">
          <DatePicker
            className="datePickerNew"
            // selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            maxDate={new Date()}
            filterDate={disabledDate}
          />
          <Space>
            <Button
              className="datepickerBtn"
              onClick={() => {
                handleClick([moment(), moment()]);
              }}
            >
              Today
            </Button>
            <Button
              className="datepickerBtn"
              onClick={() => {
                handleClick([moment().subtract('7', 'days'), moment()]);
              }}
            >
              Last 7 Days
            </Button>
            <Button
              className="datepickerBtn"
              onClick={() => {
                handleClick([moment().subtract('30', 'days'), moment()]);
              }}
            >
              One Month
            </Button>
            <Button
              className="datepickerBtn datepickerBtnSubmit"
              onClick={handleSumbit}
            >
              Ok
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
};

export default CDateRangePicker;
