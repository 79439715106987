/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AbLinePayloadTypesSingle,
  AbLinePayloadTypesSingle1,
  AcknowledgeAPIBody,
  AssignedLists,
  DailyUsageReports,
  EmployeeDetails,
  EmployeesResponseData,
  Login,
  LoginResponse,
  MarkPointPayloadTypes,
  OrganizationProfileDetails,
  PrivilegeRequest,
  RestartManagerResponse,
  RowTicketDetails,
  Taskdetails,
  TaskRequest,
  TaskResponse,
  teamUpdateRequest,
  TractorsList,
  UnAssignedLists,
  UserDetails,
  VineRowPayloadTypes,
  VineRowPayloadTypesSingle,
  VinesPayloadTypes,
  WaypointPayloadTypes,
} from '../constants/types';
import axios from './ApiInterceptor';
import {
  getHeaders,
  noneAttached,
  sortAlphaNumber,
  sortKeyWrapper,
} from './Common';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const login = async (data: Login): Promise<LoginResponse> => {
  const url = `${BASE_URL}/auth/login`;
  const response = await axios.post(url, data);
  return response.data;
};

export const loginWithToken = async (data: Login): Promise<LoginResponse> => {
  const url = `${process.env.REACT_APP_COGNIITO_ACCESS_TOKEN_URL}/api-token`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTaskList = async (
  orgURL: string,
  startTime: number,
  endTime: number,
  title: string,
  status: string,
  operator_id: number,
  operation_mode: string,
  event_type_id: number,
  tractor_id: number,
): Promise<TaskResponse[]> => {
  let url = `${orgURL}operations-planner/event?event_start_time=${startTime}&event_finish_time=${endTime}&q=${title}`;
  if (tractor_id) {
    url = url + `&tractor_id=${tractor_id}`;
  }
  if (status) {
    url = url + `&event_status=${status}`;
  }
  if (operator_id) {
    url = url + ` &operator_id=${operator_id}`;
  }
  if (operation_mode) {
    url = url + `&operation_mode=${operation_mode}`;
  }
  if (event_type_id) {
    url = url + `&recurrence_type_id=${event_type_id}`;
  }

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTask = async (
  orgURL: string,
  orgId: number,
  task_uuid: string,
): Promise<Taskdetails> => {
  const url = `${orgURL}organization/${orgId}/task/${task_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getProfileData = async (
  orgURL: string,
  orgId: number,
): Promise<OrganizationProfileDetails> => {
  const url = `${orgURL}organization/${orgId}/profile`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getEmployeesList = async (
  orgURL: string,
  orgId: number,
  page_number: number,
  page_size: number,
  search_keyword: string,
  active: boolean,
  sort?: any,
) => {
  let url = `${orgURL}organization/${orgId}/user/list?page_number=${page_number}&page_size=${page_size}&is_active=${active}`;
  url = sortKeyWrapper(url, sort);
  if (search_keyword && search_keyword?.trim() != '') {
    url += `&q=${encodeURI(search_keyword)}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getEmployeePermissionDetails = async (
  orgURL: string,
  orgId: number,
  empId: number,
): Promise<any> => {
  const url = `${orgURL}user/${empId}/profile`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getEmployeeDetails = async (
  orgURL: string,
  orgId: number,
  empId: number,
): Promise<any> => {
  const url = `${orgURL}organization/${orgId}/user/${empId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const addEmployee = async (
  orgURL: string,
  data: EmployeeDetails,
): Promise<{ msg: string }> => {
  const url = `${orgURL}user`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const createTask = async (
  orgURL: string,
  data: TaskRequest,
  org_id: number,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${org_id}/task`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getJumpAndGoRecords = async (
  orgURL: string,
  starttime: number,
  finishtime: number,
  userId: number,
): Promise<any> => {
  const url = `${orgURL}drivetractor/jumpandgo/records?page_number=1&page_size=30&start_date_time=${starttime}&end_date_time=${finishtime}&user_id=${userId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAssignedTractorDetails = async (
  orgURL: string,
  task_uuid: string,
): Promise<EmployeesResponseData> => {
  const url = `${orgURL}task/${task_uuid}/drive-actions?page_number=1&page_size=300`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const selectJumpAndGo = async (
  orgURL: string,
  payload: any,
): Promise<TaskResponse> => {
  const url = `${orgURL}task/${payload.task_uuid}/drive-actions`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteJumpAndGoRecord = async (
  orgURL: string,
  task_uuid: string,
  payload: any,
): Promise<TaskResponse> => {
  const url = `${orgURL}task/${task_uuid}/delete-jumpngo`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const editTask = async (
  orgURL: string,
  data: any,
  org_id: number,
  task_uuid: string,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${org_id}/task/${task_uuid}`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateSupervisor = async (
  orgURL: string,
  data: any,
  org_id: number,
  task_uuid: string,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${org_id}/task/${task_uuid}/update-supervisor`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateTaskstatus = async (
  orgURL: string,
  task_status: any,
  org_id: number,
  task_uuid: string,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${org_id}/task/${task_uuid}/change-status`;
  const response = await axios.put(url, task_status, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorsListData = async (
  orgURL: string,
  org_id: number,
  page_number = 1,
  heartbeat = true,
) => {
  // const url = `${orgURL}organization/${org_id}/tractor-indicators?page_number=${page_number}&page_size=25&include_heart_beat_data=true`;
  let url = `${orgURL}organization/${org_id}/tractor-indicators?`;
  if (page_number) {
    url = `${orgURL}organization/${org_id}/tractor-indicators?page_number=${page_number}&page_size=150`;
  }
  if (heartbeat) {
    url += `&include_heart_beat_data=true`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  const { data } = response;
  if (data.records) {
    const { records } = data;
    data.records = records.length > 0 ? sortAlphaNumber(records) : [];
  }
  return data;
};

export const getTractorDataById = async (
  orgURL: string,
  org_id: number,
  tractor_id: number,
  heartbeat = true,
) => {
  let url = `${orgURL}organization/${org_id}/tractor-indicators?tractor_id=${tractor_id}`;
  if (heartbeat) {
    url = url + '&include_heart_beat_data=true';
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorsData = async (
  orgURL: string,
  org_id: number,
  page_number = 1,
  page_size = 150,
  basestation_id?: number,
  sort?: any,
  search?: string,
) => {
  let url = `${orgURL}organization/${org_id}/tractor-indicators?page_number=${page_number}&page_size=${page_size}&include_heart_beat_data=true`;
  if (basestation_id) {
    url = `${url}&basestation_id=${basestation_id}`;
  }
  url = sortKeyWrapper(url, sort);
  if (search && search?.trim() != '') {
    url += `&q=${search}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  const { data } = response;
  if (data && data.records) {
    const { records } = data;
    data.records = records.length > 0 ? sortAlphaNumber(records) : [];
  }
  return data;
};

export const getTractorsList = async (
  orgURL: string,
  org_id: number,
  page_number = 1,
  page_size = 100,
  basestation_id?: number,
  sort?: any,
  search?: string,
) => {
  let url = `${orgURL}organization/${org_id}/tractor-indicators?page_number=${page_number}&page_size=${page_size}&include_heart_beat_data=true`;
  if (basestation_id) {
    url = `${url}&basestation_id=${basestation_id}`;
  }
  url = sortKeyWrapper(url, sort);
  if (search && search?.trim() != '') {
    url += `&q=${encodeURI(search)}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  const { data } = response;
  if (data.records) {
    const { records } = data;
    data.records = records.length > 0 ? sortAlphaNumber(records) : [];
  }
  return data;
};

export const getBatteryData = async (
  orgURL: string,
  org_id: number,
  page_number = 1,
  page_size = 25,
  search?: string,
) => {
  let url = `${orgURL}organization/${org_id}/battery?page_number=${page_number}&page_size=${page_size}`;
  if (search && search?.trim() != '') {
    url += `&q=${encodeURI(search)}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  const { data } = response;
  return data;
};

export const Updatetractor = async (
  orgURL: string,
  tractor_id: number,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}tractor/${tractor_id}`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const UpdateBasestation = async (
  orgURL: string,
  orgid: any,
  baseid: any,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgid}/basestation/${baseid}`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTicketsHistory = async (
  orgURL: string,
  fleetId: number,
  pageNumber: number,
  page_size: number,
  filter?: any,
  from_date_time?: any,
  to_date_time?: any,
  search?: string,
  sort?: any,
) => {
  let url = `${orgURL}fleet-maintenance/fleet/${fleetId}/maintenance-log?page_number=${pageNumber}&page_size=${page_size}`;
  let filterStr = '';
  if (filter?.status) {
    filterStr = '&status=' + filter?.status;
    url += filterStr;
  }
  if (filter?.is_service_ticket_raised) {
    filterStr = '&is_service_ticket_raised=' + filter?.is_service_ticket_raised;
    url += filterStr;
  }
  if (filter?.severityLevel) {
    filterStr = '&severity_level=' + filter?.severityLevel;
    url += filterStr;
  }
  if (filter?.equipment) {
    filterStr =
      '&equipment_id=' +
      filter?.equipment?.equipment_id +
      '&equipment_type=' +
      filter?.equipment?.equipment_type;
    url += filterStr;
  }
  if (filter?.createdAt) {
    filterStr = '&user_id=' + filter?.createdAt;
    url += filterStr;
  }
  if (from_date_time && to_date_time) {
    url += `&from_time=${from_date_time}&to_time=${to_date_time}`;
  }
  if (search && search != '') {
    url += `&search=${encodeURI(search)}`;
  }
  url = sortKeyWrapper(url, sort);
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

// export const getVersionList = async (
//   orgURL: string,
//   orgId: number,
//   buildCategory: string,
// ) => {
//   const URL = `${orgURL}organization/${orgId}/software?is_published=true&build_category=${buildCategory}`;
//   const Response = await axios.get(URL, {
//     headers: await getHeaders(),
//   });
//   return Response.data;
// };

export const createdAddticket = async (
  orgURL: string,
  fleetId: number,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetId}/maintenance-log`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const ticketaddattachment = async (
  orgURL: string,
  fleetId: number,
  fleet_maintenance_log_id: any,
  formdata: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetId}/maintenance-log/${fleet_maintenance_log_id}/add-attachment`;
  const response = await axios.post(url, formdata, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBasestationList = async (
  orgURL: string,
  org_id: number,
  page_number: number,
  page_size: number,
  search?: string,
  sort?: any,
) => {
  let url = `${orgURL}organization/${org_id}/basestation?page_number=${page_number}&page_size=${page_size}`;
  if (search && search?.trim() != '') {
    url += `&q=${encodeURI(search)}`;
  }
  url = sortKeyWrapper(url, sort);
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBasestations = async (orgURL: string, org_id: number) => {
  const url = `${orgURL}organization/${org_id}/basestation`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTicketbyId = async (
  orgURL: string,
  fleetid: number,
  tickedId: number | string,
): Promise<RowTicketDetails> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetid}/maintenance-log/${tickedId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateTicket = async (
  orgURL: string,
  fleetid: number,
  tickedId: number,
  ticketobj: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetid}/maintenance-log/${tickedId}`;
  const response = await axios.put(url, ticketobj, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const UpdateStatus = async (
  orgURL: string,
  fleetid: number,
  tickedId: number,
  ticketobj: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetid}/maintenance-log/${tickedId}/update-status`;
  const response = await axios.put(url, ticketobj, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const addComment = async (
  orgURL: string,
  fleetid: number,
  tickedId: number,
  ticketobj: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetid}/maintenance-log/${tickedId}/add-data`;
  const response = await axios.post(url, ticketobj, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateProfileImageUpload = async (
  orgURL: string,
  org_id: number,
  formData: FormData,
): Promise<OrganizationProfileDetails> => {
  const url = `${orgURL}organization/${org_id}/profile-image-upload`;
  const response = await axios.put(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUserProfile = async (orgURL: string, userId: number) => {
  const url = `${orgURL}user/${userId}/profile`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorListByBasestation = async (
  orgURL: string,
  org_id: number,
  basestation_id: number,
): Promise<{ records: TractorsList[] }> => {
  const url = `${orgURL}organization/${org_id}/tractor-indicators?page_number=1&page_size=500&basestation_id=${basestation_id}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getGroundZero = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/get-ground-zero`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export async function getRouteRelatedCoordinates(
  orgURL: any,
  routeName: string,
) {
  const url = `${orgURL}/modelmanager/route/${routeName}/points`;
  const response = await axios.get(url);

  return response.data;
}

export const acknowledgeNotifications = async (
  orgURL: string,
  userID: number,
  requestBody: AcknowledgeAPIBody,
): Promise<{ msg: string }> => {
  const url = `${orgURL}/notifications/acknowledge`;
  const response = await axios.put(url, requestBody, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getNotificationsList = async (
  orgURL: string,
  userID: number,
  filter: any,
  page_number: number,
  page_size: number,
) => {
  let url = `${orgURL}user/${userID}/notifications?page_number=${page_number}&page_size=${page_size}`;
  if (filter && filter !== 'all') {
    url += `&category=${filter}`;
  }
  // let url = `${orgURL}/user/${userID}/notifications?page_number=${page_number}&page_size=${page_size}&level=${tempFilterObject.level}&is_acknowledged=${tempFilterObject.acknowledge}&tractor_id=${tempFilterObject.tractor}&category=${tempFilterObject.category}`;
  // if (tempFilterObject.fromtime && tempFilterObject.totime) {
  //   url =
  //     url +
  //     `&from_time=${tempFilterObject.fromtime}&to_time=${tempFilterObject.totime}`;
  // }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllMapTractors = async (orgURL: string, org_id: number) => {
  const url = `${orgURL}tractor?organization_id=${org_id}&fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAssignedTractorsList = async (
  orgURL: string,
  org_id: number,
  basestation_id: number,
): Promise<{ records: AssignedLists[] }> => {
  const url = `${orgURL}organization/${org_id}/basestation/${basestation_id}/tractor?page_number=1&page_size=500`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getRoute = async (
  orgURL: string,
  farmId: number,
  search?: any,
  sort_column?: any,
  sort_by?: any,
  implement?: string,
) => {
  // let url = `${orgURL}farm/${farmId}/routes?`;
  let url = `${orgURL}farm/${farmId}/routes?fetch_all=true`;
  if (sort_column) {
    url = `${url}&sort_column=${sort_column}`;
  }
  if (sort_by) {
    url = `${url}&sort_by=${sort_by}`;
  }
  if (search) {
    url = `${url}&q=${search}`;
  }

  if (implement && implement !== noneAttached) {
    url = `${url}&implement_name=${implement}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUnassignedTractorsList = async (
  orgURL: string,
  org_id: number,
): Promise<UnAssignedLists[]> => {
  const url = `${orgURL}organization/${org_id}/basestation/tractor/not-assign-to-basestation`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateCheckedData = async (
  orgURL: string,
  org_id: number,
  basestation_id: number,
  payload: any,
): Promise<any> => {
  const url = `${orgURL}organization/${org_id}/basestation/${basestation_id}/assign-tractor`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getIndicators = async (orgURL: string, fleetId: number) => {
  const url = `${orgURL}fleet/${fleetId}/indicator`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getRoutePoints = async (
  orgURL: string,
  farmId: number,
  routeId: number,
) => {
  const url = `${orgURL}farm/${farmId}/route/${routeId}/points`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateUserProfile = async (
  orgURL: string,
  userId: number,
  payload: any,
): Promise<{ records: any }> => {
  const url = `${orgURL}user/${userId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const changePassword = async (
  orgURL: string,
  userId: number,
  payload: any,
): Promise<{ records: any }> => {
  const url = `${orgURL}user/${userId}/password-change`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllVinePoints = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/vines?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getVineRowsData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getVineRowJumpAndGoData = async (
  orgURL: string,
  orgId: number,
  selectedTractor: number,
  start_date_time: number,
  end_date_time: number,
) => {
  const url = `${orgURL}organization/${orgId}/tractor/${selectedTractor}/planner/vinerow/driveaction?from_date_time=${start_date_time}&to_date_time=${end_date_time}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getJumpAndGoVineRowsData = async (
  orgURL: string,
  farmId: number,
  jAndGId: string,
) => {
  const url = `${orgURL}task/drive-actions/${jAndGId}/planner/vinerow/travelled-path`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getVineBlocksData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-blocks?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFeedLanesData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows?fetch_all=true&category=feed_lane`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getPolygonsData = async (
  orgURL: string,
  farmId: number,
  fetchGreen = false,
) => {
  let url = `${orgURL}farm/${farmId}/cartography/polygons?fetch_all=true`;
  if (fetchGreen) {
    url += '&color=green';
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getSuggestedPolygonsData = async (
  orgURL: string,
  farmId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/suggested-polygons?is_approved=False`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getPolygonData = async (
  orgURL: string,
  farmId: number,
  polygon_uuid: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/polygons/${polygon_uuid}/vertices`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBrownPolygonData = async (
  orgURL: string,
  farmId: number,
  polygonUuid: number,
  color?: string,
) => {
  let url = `${orgURL}farm/${farmId}/cartography/suggested-polygons/discover/${polygonUuid}/vertices`;
  url = `${url}${color ? '?color=' + color : ''}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getWaypointsData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/waypoints?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getChargePointsData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}organization/${farmId}/charge-points?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarksData = async (
  orgURL: string,
  farmId: number,
  type: string,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/marks?fetch_all=true&type=${type}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getImplementsData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}fleet/${farmId}/config/implements?is_active=true&fetch_all=true&is_autonomy_registered_implement=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getReportsData = async (
  orgURL: string,
  org_id: number,
): Promise<{ records: DailyUsageReports[] }> => {
  const url = `${orgURL}organization/${org_id}/reports-all?page_number=1&page_size=100`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateUserProfileImageUpload = async (
  orgURL: string,
  userId: number,
  formData: FormData,
): Promise<UserDetails> => {
  const url = `${orgURL}user/${userId}/profile-image-upload`;
  const response = await axios.put(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFleetUsageReports = async (
  orgURL: string,
  org_id: number,
  page_number: number,
  page_size: number,
  sort?: any,
  report_id?: number,
  year?: number,
) => {
  let url = `${orgURL}organization/${org_id}/reports/generated?page_number=${page_number}&page_size=${page_size}`;
  url = sortKeyWrapper(url, sort);
  if (report_id) {
    url = url + `&report_id=${report_id}`;
  }
  if (year) {
    url = url + `&year=${year}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllTractorSnapShot = async (
  orgURL: string,
  farmId: number,
  tractor_id: number,
  page_number: number,
  page_size: number,
  status: string,
  createdAt: string,
  from_time: string,
  to_time: string,
  // search_key?: any,
  sort?: any,
) => {
  let URL = `${orgURL}farm/${farmId}/camera-snapshot?page_number=${page_number}&page_size=${page_size}`;
  if (tractor_id) {
    URL += `&tractor_id=${tractor_id}`;
  }
  if (status && status.trim() !== '') {
    URL += `&status=${status}`;
  }
  if (createdAt && createdAt.trim() !== '') {
    URL += `&createdAt=${createdAt}`;
  }
  if (from_time) {
    URL += `&from_time=${from_time}`;
  }
  if (to_time) {
    URL += `&to_time=${to_time}`;
  }
  URL = sortKeyWrapper(URL, sort);
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getjumpandgoview = async (
  orgURL: string,
  page_number: number,
  page_size: number,
  tractor_id?: string,
  start_date_time?: any,
  end_date_time?: any,
  user_id?: any,
  duration?: any,
  distance?: any,
) => {
  let URL = `${orgURL}drivetractor/jumpandgo/records?page_number=${page_number}&page_size=${page_size}&is_active=true`;
  if (tractor_id && tractor_id != '') {
    URL = URL + `&tractor_id=${tractor_id}`;
  }
  if (user_id && user_id != '') {
    URL = URL + `&user_id=${user_id}`;
  }
  if (duration && duration != '') {
    URL = URL + `&duration=${duration}`;
  }
  if (distance && distance != '') {
    URL = URL + `&distance=${distance}`;
  }
  if (start_date_time && end_date_time) {
    URL =
      URL +
      `&start_date_time=${start_date_time}&end_date_time=${end_date_time}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getOperators = async (
  orgURL: string,
  start_date_time?: any,
  end_date_time?: any,
) => {
  let URL = `${orgURL}drivetractor/jumpandgo/operator?`;
  if (start_date_time && end_date_time) {
    URL =
      URL +
      `&start_date_time=${start_date_time}&end_date_time=${end_date_time}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const stopDriveTractor = async (
  orgURL: string,
  actionUuid: string,
  obj: any,
) => {
  const URL = `${orgURL}drivetractor/jumpandgo/${actionUuid}/change-action`;
  const Response = await axios.put(URL, obj, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getRolesList = async (orgURL: string, orgId: any) => {
  const URL = `${orgURL}organization/${orgId}/roles/team-roles`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getImplementsList = async (
  orgURL: string,
  fleetid: number,
  page_number: number,
  page_size: number,
  searchKey: string,
  active: boolean,
  implement_type: string,
  sort?: string,
  fetch_all?: boolean,
) => {
  let url = `${orgURL}fleet/${fleetid}/config/implements?page_number=${page_number}&page_size=${page_size}&is_active=${active}`;

  if (implement_type && implement_type.trim() !== '')
    url += `&implement_type=${implement_type.trim()} `;

  if (searchKey && searchKey.trim() !== '')
    url += `&q=${encodeURI(searchKey.trim())}`;

  if (fetch_all) url += `&fetch_all=true`;
  if (sort) url = sortKeyWrapper(url, sort);
  const Response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getImplementsBySearch = async (
  orgURL: string,
  organization_id: number,
  searchKey: string,
) => {
  let url = `${orgURL}fleet/${organization_id}/config/implements?`;

  if (searchKey && searchKey.trim() !== '')
    url += `q=${encodeURI(searchKey.trim())}`;

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });

  return response.data;
};

export const getImplementsList1 = async (orgURL: string, fleetid: number) => {
  const url = `${orgURL}fleet/${fleetid}/config-implements?q=`;
  const Response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const getTaskmasterHistory = async (
  orgURL: string,
  org_id: number,
  status: string,
  pagenumber: number,
  page_size: number,
  title: string,
  fromtime: any,
  totime: any,
  tractorId?: any,
) => {
  let URL = `${orgURL}organization/${org_id}/task?page_number=${pagenumber}&page_size=${page_size}&status=${status}`;
  if (title && fromtime && totime) {
    URL = URL + `&from_time=${fromtime}&to_time=${totime}&q=${title}`;
  } else if (title === '' && fromtime && totime) {
    URL = URL + `&from_time=${fromtime}&to_time=${totime}`;
  } else if (title !== '' && fromtime === '' && totime === '') {
    URL = URL + `&q=${title}`;
  } else if (title !== '' && fromtime && totime === '') {
    URL = URL + `&q=${title}`;
  } else if (title !== '' && fromtime === '' && totime) {
    URL = URL + `&q=${title}`;
  }
  if (tractorId !== '' && tractorId !== 'Select Tractor') {
    URL = URL + `&tractor_id=${tractorId}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getManufactureList = async (orgURL: string, fleetid: number) => {
  const URL = `${orgURL}fleet/${fleetid}/config/imm-manufacturer`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getManufactureModelsList = async (
  orgURL: string,
  fleetid: number,
  manufacture: string,
) => {
  const URL = `${orgURL}fleet/${fleetid}/config/imm-model?manufacturer=${manufacture}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getCategoryList = async (orgURL: string, category: any) => {
  const URL = `${orgURL}fleet/config/implements/lookup?category=${category}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getPermissionList = async (
  orgURL: string,
  orgid: number,
  roleKey: string,
) => {
  const URL = `${orgURL}organization/${orgid}/role/${roleKey}/team-permissions`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const savepermission = async (
  orgURL: string,
  orgid: number,
  obj: any,
) => {
  const URL = `${orgURL}organization/${orgid}/team`;
  const Response = await axios.post(URL, obj, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const ConfirmPermission = async (
  orgURL: string,
  orgid: number,
  roleid: any,
  obj: any,
) => {
  const URL = `${orgURL}organization/${orgid}/role/${roleid}/team-permissions`;
  const Response = await axios.post(URL, obj, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const SaveImplements = async (
  orgURL: string,
  fleetid: number,
  addimplement: any,
) => {
  const URL = `${orgURL}fleet/${fleetid}/config/implements`;
  const Response = await axios.post(URL, addimplement, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const UpdateImplements = async (
  orgURL: string,
  fleetid: number,
  config_implements_uuid: any,
  updateimpobj: any,
) => {
  const URL = `${orgURL}fleet/${fleetid}/config/implements/${config_implements_uuid}`;
  const Response = await axios.put(URL, updateimpobj, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getimplementbyId = async (
  orgURL: string,
  fleetid: number,
  config_implements_uuid: any,
) => {
  const URL = `${orgURL}fleet/${fleetid}/config/implements/${config_implements_uuid}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getImplementConfigById = async (
  orgURL: string,
  tractorId: number,
  driveActionUuid: string,
  implementUuid: string,
) => {
  const URL = `${orgURL}tractor/${tractorId}/drive-actions/${driveActionUuid}/attached-implement?implement_uuid=${implementUuid}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const saveImm = async (
  orgURL: string,
  fleetid: number,
  immdata: any,
) => {
  const URL = `${orgURL}fleet/${fleetid}/config/imm`;
  const Response = await axios.post(URL, immdata, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getActions = async (
  orgURL: string,
  actionId: number,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}drivetractor/jumpandgo/${actionId}/autonomous-controls?sort_order=asc&page_number=${page_number}&page_size=${page_size}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getFleetSoftwareHistory = async (
  orgURL: string,
  farmid: any,
  tractorid: any,
  pagenumber: number,
  page_size: number,
) => {
  const URL = `${orgURL}fleet/${farmid}/tractor/${tractorid}/software-history/?page_number=${pagenumber}&page_size=${page_size}&q=`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getFleetSoftwarelogsHistory = async (
  orgURL: string,
  farmid: any,
  tractorid: any,
  id: any,
  pagenumber: number,
  page_size: number,
) => {
  const URL = `${orgURL}fleet/${farmid}/tractor/${tractorid}/software-history/${id}/details?page_number=${pagenumber}&page_size=${page_size}&fetch_all=true`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getVersionList = async (
  orgURL: string,
  orgId: number,
  buildCategory: string,
  tractorModel?: string,
) => {
  let URL = `${orgURL}organization/${orgId}/software?is_published=true&build_category=${buildCategory}`;
  if (tractorModel) {
    URL = URL += `&tractor_model=${tractorModel}`;
  }

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getAllVersionList = async (
  orgURL: string,
  orgId: number,
  buildCategory: string,
  tractorModel?: string,
) => {
  let URL = `${orgURL}fleet-ota/software-version/dropdown?is_published=true&build_category=${buildCategory}`;
  if (tractorModel) {
    URL = URL += `&tractor_model=${tractorModel}`;
  }

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getRestartManager = async (
  orgURL: string,
  fleetid: number,
  page?: number,
) => {
  const pageNumber = page ?? 1;
  const URL = `${orgURL}fleet/${fleetid}/restart-manager?page_number=${pageNumber}&page_size=25`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getJumpAndGoAutonomousControls = async (
  orgURL: string,
  driveActionUuid: number,
  pageNumber: number,
  page_size: number,
) => {
  const URL = `${orgURL}drivetractor/jumpandgo/${driveActionUuid}/autonomous/history?sort_order=asc&page_number=${pageNumber}&page_size=${page_size}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTasksearch = async (orgURL: string, name: string) => {
  let URL = `${orgURL}organization/members?type=MEMBER&page_number=1&page_size=200`;
  if (name) URL += `&q=${name}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  if (Response) return Response.data;
  else return { records: [] };

  // return Response.data;
};

export const getTicketById = async (orgURL: string, fleet_id: any, id: any) => {
  const URL = `${orgURL}fleet-maintenance/fleet/${fleet_id}/maintenance-log/${id}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getCommentsTickets = async (
  orgURL: string,
  fleet_id: any,
  id: any,
  data_key: any,
) => {
  const URL = `${orgURL}fleet-maintenance/fleet/${fleet_id}/maintenance-log/${id}/get-data?data_key=${data_key}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updateRestartManager = async (
  orgURL: string,
  fleetId: number,
  tractorId: number,
  payload: any,
) => {
  const url = `${orgURL}fleet/${fleetId}/tractor/${tractorId}/restart-component`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMyTask = async (
  orgURL: string,
  org_id: number,
  userId: number,
  page_number: any,
  page_size: number,
) => {
  const URL = `${orgURL}organization/${org_id}/task?page_number=${page_number}&page_size=${page_size}&user_id=${userId}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getGuardrailsList = async (
  orgURL: string,
  tempFilterObject: string,
  start_date_time: any,
  end_date_time: any,
  guard_rail_type: string,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}drivetractor/jumpandgo/guardrails?page_number=${page_number}&page_size=${page_size}&tractor_id=${tempFilterObject}&from_time=${start_date_time}&to_time=${end_date_time}&guard_rail_type=${guard_rail_type}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTasksByJumpAndGo = async (orgURL: string, UUID: number) => {
  const URL = `${orgURL}drivetractor/jumpandgo/${UUID}/task?page_number=1&page_size=100`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTractorIndicatorSummaryData = async (
  orgURL: string,
  tractor_id: number,
) => {
  const url = `${orgURL}tractor/${tractor_id}/indicator/summary`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFleetDiagnostics = async (
  orgURL: string,
  tractor_id: number,
  status: string,
  indicator_type: string,
  level: string,
  sub_system: string,
  from_date_time: string,
  to_date_time: string,
  page_number: number,
  page_size: number,
  search: string,
  sort?: any,
) => {
  let URL = `${orgURL}tractor/${tractor_id}/indicator/diagnostics?tractor_id=${tractor_id}`;
  if (page_size) {
    URL = URL + `&page_size=${page_size}`;
  }
  if (page_number) {
    URL = URL + `&page_number=${page_number}`;
  }
  if (level) {
    URL = URL + `&level=${level}`;
  }
  if (sub_system) {
    URL = URL + `&sub_system=${sub_system}`;
  }
  if (status) {
    URL = URL + `&status=${status}`;
  }
  if (indicator_type) {
    URL = URL + `&indicator_key=${indicator_type}`;
  }
  if (from_date_time && to_date_time) {
    URL = URL + `&from_date=${from_date_time}&to_date=${to_date_time}`;
  }
  if (search.trim() != '') URL += `&q=${search}`;
  URL = sortKeyWrapper(URL, sort);
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTravelledRoute = async (
  orgURL: string,
  orgId: number,
  farmId: number,
) => {
  const url = `${orgURL}organization/${orgId}/farm/${farmId}/copycat-travelled-routes?date=2021-07-28`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getActualRoute = async (
  orgURL: string,
  orgId: number,
  farmId: number,
  copycat_travelled_route_uuid: string,
) => {
  const url = `${orgURL}organization/${orgId}/farm/${farmId}/copycat-travelled-path?date=2021-07-28&copycat_travelled_route_uuid=${copycat_travelled_route_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDesiredRoute = async (
  orgURL: string,
  orgId: number,
  farmId: number,
  copycat_travelled_route_uuid: string,
) => {
  const url = `${orgURL}organization/${orgId}/farm/${farmId}/copycat-desired-path?date=2021-07-28&copycat_travelled_route_uuid=${copycat_travelled_route_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getRecordedVideo = async (
  orgURL: string,
  orgId: number,
  fromdate: any,
  endTime: any,
) => {
  const url = `${orgURL}farm/${orgId}/analytics/videos-files-monthly?from_date=${fromdate}&to_date=${endTime}`;

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDayview = async (
  orgURL: string,
  orgId: number,
  fromdate: any,
  endTime: any,
  tractor_id: any,
) => {
  const url = `${orgURL}farm/${orgId}/analytics/videos-files?from_date=${fromdate}&to_date=${endTime}&tractor_id=${tractor_id}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getVideoURL = async (
  orgUrl: string,
  farmId: number,
  tractorId: number,
  video_time: number,
) => {
  const url = `${orgUrl}farm/${farmId}/analytics/videos-urls?video_time=${video_time}&tractor_id=${tractorId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateSoftwareAPI = async (
  orgUrl: string,
  tractorId: number,
  fleetId: number,
  payload: any,
) => {
  const url = `${orgUrl}fleet/${fleetId}/tractor/${tractorId}/software-update`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const setPolygon = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/polygons`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updatePolygonData = async (
  orgURL: string,
  farmId: number,
  polygonId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/polygons/${polygonId}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getPolyColorData = async (orgUrl: string, farmId: number) => {
  const url = `${orgUrl}farm/${farmId}/cartography/polygone-colors`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveVinePoints = async (
  orgURL: string,
  farmId: number,
  payload: VinesPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vines`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveVineRowsData = async (
  orgURL: string,
  farmId: number,
  payload: VineRowPayloadTypesSingle,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const updateVineRowsData = async (
  orgURL: string,
  farmId: number,
  vineRowId: number,
  payload: VineRowPayloadTypesSingle,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows/${vineRowId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deletePolygonFun = async (url: string) => {
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveWayPointData = async (
  orgURL: string,
  farmId: number,
  payload: WaypointPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/waypoints`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteWaypointFun = async (
  orgURL: string,
  farmId: number,
  waypointId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/waypoints/${waypointId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const editWayPointData = async (
  orgURL: string,
  farmId: number,
  waypointId: number,
  payload: WaypointPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/waypoints/${waypointId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteVineFun = async (
  orgURL: string,
  farmId: number,
  vineId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vines/${vineId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const editVinePointData = async (
  orgURL: string,
  farmId: number,
  vineId: number,
  payload: VinesPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vines/${vineId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteVineRowFun = async (
  orgURL: string,
  farmId: number,
  vineRowId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows/${vineRowId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const executeCommand = async (
  orgURL: string,
  drive_action_uuid: string,
  payload: any,
) => {
  const url = `${orgURL}operations/driveactions/${drive_action_uuid}/controls/execute`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const setVineBlock = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/vine-blocks`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const editVineBlock = async (
  orgURL: string,
  farmId: number,
  vineBlockId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/vine-blocks/${vineBlockId}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const deleteVineBlockFun = async (
  orgURL: string,
  farmId: number,
  vineBlockId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-blocks/${vineBlockId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getVineBlocks = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/navigation`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadpoly = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/upload-polygons`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadVineRow = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows-upload`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadVineBlock = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-block-rows-upload`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadFeedLanes = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/feed-lanes-upload`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadVines = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/upload-vines`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const setVineLane = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/vine-lane`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const setVineLaneAutoGenarate = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/vine-lanes/auto-generate`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getVineLaneData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-lane?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteVineLaneFun = async (
  orgURL: string,
  farmId: number,
  vineLaneId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-lane/${vineLaneId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDriveActionSnapShots = async (
  orgURL: string,
  orgId: number,
  driveAction: string[],
  pageNumber: number,
) => {
  const url = `${orgURL}organization/${orgId}/fleet-usage/get-snapshots?page_number=${pageNumber}&page_size=1`;
  const response = await axios.post(
    url,
    {
      task_uuid: '',
      drive_action_uuids: driveAction,
    },
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getDriveActionVideos = async (
  orgURL: string,
  orgId: number,
  driveAction: string[],
  pageNumber: number,
) => {
  const url = `${orgURL}organization/${orgId}/fleet-usage/get-video-files?page_number=${pageNumber}&page_size=1`;
  const response = await axios.post(
    url,
    {
      task_uuid: '',
      drive_action_uuids: driveAction,
    },
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getSnapshot = async (
  orgURL: string,
  transactionId: any,
  tractorId: any,
) => {
  const url = `${orgURL}tractor/${tractorId}/camera_snapshot/images/${transactionId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getHitchSnapshot = async (
  orgURL: string,
  driveActionUUID: string,
  tractorId: string | number,
  cameraType: 'pto' | 'front',
) => {
  // farm/1/snapshot/image-url?category=JNG&drive_action_uuid=16691812700440&camera=pto
  const url = `${orgURL}farm/${tractorId}/snapshot/image-url?category=JNG&drive_action_uuid=${driveActionUUID}&camera=${cameraType}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMapFleetIndicators = async (
  orgURL: string,
  orgId: any,
  driveAction: any,
) => {
  const url = `${orgURL}organization/${orgId}/fleet-usage/get-indicators`;
  const response = await axios.post(
    url,
    {
      task_uuid: '',
      drive_action_uuids: [driveAction],
      indicator_level: [3],
    },
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getMapSpeedTests = async (
  orgURL: string,
  orgId: any,
  driveAction: any,
) => {
  const url = `${orgURL}organization/${orgId}/analytics/${driveAction}/tractor-speed-test`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMapFleetTickets = async (
  orgURL: string,
  orgId: any,
  driveAction: any,
) => {
  const url = `${orgURL}organization/${orgId}/fleet-usage/get-fleet-tickets`;
  const response = await axios.post(
    url,
    {
      task_uuid: '',
      drive_action_uuids: [driveAction],
    },
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getDriveActionPath = async (orgURL: string, driveAction: any) => {
  const url = `${orgURL}task/drive-actions/${driveAction}/travelled-path-by-planner`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getJgDetails = async (
  orgURL: string,
  drive_action_uuid: number,
) => {
  const url = `${orgURL}drivetractor/jumpandgo/${drive_action_uuid}/analytics`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgTags = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}organization/${orgId}/lookup?category=tags`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgEquipments = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}organization/${orgId}/lookup/equipment`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgErrorCodesData = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}organization/${orgId}/diagnosis-codes?all_error_codes=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgSubsystemsData = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}organization/${orgId}/diagnosis-codes?all_subsystem=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSoftwaresList = async (orgURL: string, orgId: number) => {
  const URL = `${orgURL}organization/${orgId}/software-list`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTractorHealth = async (orgURL: string, tractorId: number) => {
  const URL = `${orgURL}fleet/software/tractor/${tractorId}/health`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getJumpAndGoAllDay = async (
  orgURL: string,
  org_id: number,
  tractor_id?: number,
  from_date_time?: number | undefined,
  to_date_time?: number | undefined,
) => {
  let URL = `${orgURL}organization/${org_id}/fleet/analytics/tractor`;
  if (tractor_id) {
    URL = `${orgURL}organization/${org_id}/tractor/${tractor_id}/analytics`;
  }
  if (from_date_time && to_date_time) {
    URL =
      URL + `?from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const subscribe = async (orgURL: string, payload: any) => {
  const URL = `${orgURL}notification/subscribe`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTeamListByRoleId = async (
  orgURL: string,
  org_id: number,
  role_id: number,
) => {
  const URL = `${orgURL}organization/${org_id}/team?role_id=${role_id}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

// export const updateUserDetails = async (
//   orgURL: string,
//   user_id: number,
//   payload: any,
// ) => {
//   const url = `${orgURL}user/atom/${user_id}`;
//   const response = await axios.put(url, payload, {
//     headers: await getHeaders(),
//   });
//   return response.data;
// };

export const updateUserDetails = async (
  orgURL: string,
  user_id: number,
  payload: any,
) => {
  const url = `${orgURL}user/${user_id}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getRoleTeams = async (orgURL: string, orgId: any) => {
  const URL = `${orgURL}organization/${orgId}/roles/team-users`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getJumpAndGoDetails = async (
  orgURL: string,
  drive_action_uuid: string,
) => {
  const URL = `${orgURL}drivetractor/jumpandgo/${drive_action_uuid}/details`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getOrgCodes = async (
  orgURL: string,
  orgId: number,
  userId: number,
) => {
  const url = `${orgURL}organization/${orgId}/user/${userId}/privileges`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const checkTokenData = async (
  token: string,
): Promise<{ msg: string }> => {
  const url = `${BASE_URL}/user/atom/user/confirm-email`;
  const response = await axios.put(url, { token }, {});
  return response.data;
};

export const addPassword = async (payload: {
  token: string;
  password: string;
  action: string;
  app_type: string;
}): Promise<{ msg: string }> => {
  // let url = `${BASE_URL}/user/atom/user/add-password`;

  const url = `${process.env.REACT_APP_ATOM_URL}/user/atom/user/add-password`;
  const response = await axios.put(url, payload, {});
  return response.data;
};

export const getForgetPassword = async (obj: any) => {
  // let url = `${BASE_URL}/user/atom/user/forgot-password-link`;
  const url = `${process.env.REACT_APP_ATOM_URL}/user/atom/user/forgot-password-link`;
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj),
  };
  return fetch(url, requestOptions).then((data) => data);
  // const response = await axios.post(url, obj, {
  //   headers: await getHeaders(),
  // });
  // return response.data;
};

export const imageUploadMethod = async (
  orgURL: string,
  orgid: any,
  payload: any,
) => {
  const url = `${orgURL}organization/${orgid}/media/upload`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateorganization = async (
  orgURL: string,
  orgid: any,
  payload: any,
) => {
  const url = `${orgURL}organization/${orgid}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getCrops = async (orgURL: string, farmName: string) => {
  const url = `${orgURL}factory/mt_lookup?category=${farmName}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const createLogOut = async (orgURL: string, payload: any) => {
  const URL = `${orgURL}auth/logout`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getVineLanesData = async (
  orgURL: string,
  farmId: number,
  polygonUuid: string,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/${polygonUuid}/vine-lane`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getImplementAttached = async (
  orgURL: string,
  tractorId: number,
) => {
  const url = `${orgURL}tractor/${tractorId}/attached-implement`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });

  return response.data;
};
export const getPreviewPath = async (
  orgURL: string,
  drive_action_uuid: string,
) => {
  // /drivetractor/{drive_action_uuid}/remoteav/preview-path
  const url = `${orgURL}drivetractor/${drive_action_uuid}/remoteav/preview-path`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });

  return response.data;
};
export const getVineBlockName = async (
  orgURL: string,
  farm_id: number,
  polygonUuid: string,
) => {
  const url = `${orgURL}farm/${farm_id}/cartography/${polygonUuid}/vine-blocks`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });

  return response.data;
};

export const executeRemoteAVCommand = async (orgURL: string, payload: any) => {
  const url = `${orgURL}drivetractor/remoteav/remote`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const addRemoteAVCommand = async (
  orgURL: string,
  payload: any,
  drive_action_uuid: string,
) => {
  const URL = `${orgURL}drivetractor/${drive_action_uuid}/remoteav/command`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const validateOpsCode = async (orgURL: string, data: any) => {
  const url = `${orgURL}drivetractor/jumpandgo/lock-tractor`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorTraveledPointsBasedOnPlanner = async (
  orgURL: string,
  tractorId: number,
) => {
  const url = `${orgURL}tractor/${tractorId}/drive-actions/tractor-live-path`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getGuraudrailsCamera = async (
  orgURL: string,
  orgId: number,
  guard_rail_triggered_uuid: number,
) => {
  const url = `${orgURL}organization/${orgId}/guardrails/${guard_rail_triggered_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorTraveledPointsIndicators = async (
  orgURL: string,
  tractorId: number,
) => {
  const url = `${orgURL}tractor/${tractorId}/driveactions/tractor-live-indicators?indicator_level=3`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const addMapmakerImage = async (
  orgURL: string,
  org_id: number,
  payload: any,
) => {
  const url = `${orgURL}organization/${org_id}/farm-layout-image`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const approvePolygon = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/suggested-polygons/approve	`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const discoverPolygon = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/suggested-polygons/discover`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getBrwonPolygonsSuggestedPolygonsData = async (
  orgURL: string,
  farmId: number,
  polygonUuid: string,
  color?: string,
) => {
  let url = `${orgURL}farm/${farmId}/cartography/suggested-polygons/discover/${polygonUuid}`;
  url = `${url}${color ? '?color=' + color : ''}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDriveActionSinglePath = async (
  orgURL: string,
  driveAction: any,
) => {
  const url = `${orgURL}task/drive-actions/${driveAction}/travelled-path`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorTraveledPointsGuardRails = async (
  orgURL: string,
  tractorId: number,
) => {
  const url = `${orgURL}tractor/${tractorId}/drive-actions/tractor-live-camera-snapshot?page_number=1&page_size=100`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTurnServerDetails = async (
  orgURL: string,
  orgId: number,
  tractorId: string,
) => {
  const url = `${orgURL}organization/${orgId}/livestreamconfig?tractor_id=${tractorId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const generateFleetReport = async (
  orgURL: string,
  orgId: number,
  reportDate: string,
) => {
  const url = `${orgURL}reports/organization/${orgId}/generate-daily-fleet-usage-report-version3?report_date=${reportDate}`; // 2022-04-20
  const response = await axios.put(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const createVideoLog = async (
  orgURL: string,
  orgId: number,
  tractorId: string,
  payload: any,
) => {
  const URL = `${orgURL}organization/${orgId}/tractor/${tractorId}/live-stream-watch-log`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updateVideoLog = async (
  orgURL: string,
  orgId: number,
  tractorId: string,
  payload: any,
) => {
  const URL = `${orgURL}organization/${orgId}/tractor/${tractorId}/live-stream-watch-log`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

// delete marks api
export const deleteMarkFun = async (
  orgURL: string,
  farmId: number,
  payload: { mark_ids: number[] },
) => {
  const url = `${orgURL}farm/${farmId}/cartography/marks/bulk-delete`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getJAGFleetReport = async (
  orgURL: string,
  orgId: number,
  driveActionUUID: string,
) => {
  const URL = `${orgURL}organization/${orgId}/fleet/driveaction/${driveActionUUID}/fleet-usage-data`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getJAGCummulativeFleetReport = async (
  orgURL: string,
  orgId: number,
  startTime?: number,
  endTime?: number,
  tractorId?: number,
) => {
  let URL = `${orgURL}organization/${orgId}/fleet/analytics/fleet-usage-data?`;
  if (startTime) {
    URL += `&from_date_time=${startTime}`;
  }
  if (endTime) {
    URL += `&to_date_time=${endTime}`;
  }
  if (tractorId) {
    URL += `&tractor_id=${tractorId}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const downloadMapMakerFeature = async (
  orgUrl: string,
  orgId: number,
  farmId: number,
  category: string,
) => {
  const url = `${orgUrl}organization/${orgId}/farm/${farmId}/cartography/download-csv?category=${category}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
    responseType: 'blob',
  });
  return response;
};

export const getFleetSettingData = async (orgUrl: string, orgId: number) => {
  const url = `${orgUrl}organization/${orgId}/update-fleet-settings`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const toGetCameraUrl = async (
  orgUrl: string,
  orgId: number | undefined,
  transaction_id: number | undefined,
) => {
  const url = `${orgUrl}organization/${orgId}/guardrails/${transaction_id}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateFleetSettingData = async (
  orgUrl: string,
  orgId: number,
  payload: any,
) => {
  const url = `${orgUrl}organization/${orgId}/update-fleet-settings`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const editMarkPointData = async (
  orgURL: string,
  farmId: number,
  markPointId: number,
  payload: MarkPointPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/marks/${markPointId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getAllAnalysticsTractors = async (
  orgURL: string,
  org_id: number,
) => {
  const url = `${orgURL}fleet-analytics/organization/${org_id}/fleet-analytics/tractor-list`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const grantTractorRemoteAccess = async (
  orgURL: string,
  payload: any,
) => {
  const URL = `${orgURL}tractor/remote-access/grant-access`;
  const response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const grantOrgRemoteAccess = async (
  orgURL: string,
  orgId: number,
  payload: any,
) => {
  const URL = `${orgURL}organization/${orgId}/remote-access`;
  const response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateLatLong = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/set-ground-zero`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const uploadVineLanes = async (
  orgURL: string,
  orgId: number,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}organization/${orgId}/farm/${farmId}/cartography/upload-vinelane`;
  const response = await axios.put(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveVineRowsAutoGenerateData = async (
  orgURL: string,
  farmId: number,
  payload: VineRowPayloadTypes,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows/auto-generation`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const publishVineBlockWithName = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-blocks/publish`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUploadFiles = async (orgUrl: string, tractorId: number) => {
  const url = `${orgUrl}tractor/${tractorId}/control-upload-files`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadFilesControl = async (
  orgURL: string,
  tractorId: number,
  payload: any,
) => {
  const URL = `${orgURL}tractor/${tractorId}/control-upload-files`;
  const response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const DeleteAttachmentFile = async (
  orgURL: string,
  fleetId: number,
  id: number,
  fleetmaintenancelogId: number,
  payload: any,
) => {
  const url = `${orgURL}fleet-maintenance/fleet/${fleetId}/maintenance-log/${fleetmaintenancelogId}/delete-data/${id}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTremsAndConditions = async (
  orgUrl: string,
  orgId: number,
  userId: any,
) => {
  const url = `${orgUrl}organization/${orgId}/user/${userId}/compliance/check?app_name=web&trigger_at=Login,2017_Login`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const UpdateComplianceCheck = async (
  orgUrl: string,
  orgId: number,
  userId: any,
  payload: any,
) => {
  const url = `${orgUrl}organization/${orgId}/user/${userId}/update-compliance-check`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSystemComponent = async (
  orgURL: string,
  fleetid: number,
  tractorId: any,
): Promise<{ records: RestartManagerResponse[] }> => {
  const URL = `${orgURL}fleet/${fleetid}/tractor/${tractorId}/system-component`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const forwardTicketDetails = async (
  orgUrl: string,
  fleetid: number,
  ticketid: any,
  payload = {},
) => {
  const url = `${orgUrl}fleet-maintenance/fleet/${fleetid}/maintenance-log/${ticketid}/forward-ticket`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllControls = async (
  orgURL: string,
  tractor_id: string,
  control_name: string,
  from_time: string,
  to_time: string,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}operations/driveactions/controls?tractor_id=${tractor_id}&control_name=${control_name}&page_number=${page_number}&page_size=${page_size}&from_time=${from_time}&to_time=${to_time}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getSafeDrive = async (
  orgURL: string,
  tractor_id: string,
  from_time: string,
  to_time: string,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}operations/driveactions/controls/safe-drive?tractor_id=${tractor_id}&page_number=${page_number}&page_size=${page_size}&from_time=${from_time}&to_time=${to_time}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getFleetIndicatorHistory = async (
  orgURL: string,
  fleetId: number,
  tractor_id: string,
  indicator_key: string,
  from_date_time: string,
  to_date_time: string,
  page_number: number,
  page_size: number,
  indicator_level: number,
) => {
  let URL = `${orgURL}fleet/${fleetId}/indicator/history?tractor_id=${tractor_id}&indicator_key=${indicator_key}&page_number=${page_number}&page_size=${page_size}&indicator_level=${indicator_level}`;
  if (from_date_time && to_date_time) {
    URL =
      URL + `&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getShadowDetails = async (
  orgURL: string,
  drive_action_uuid: string,
  acr_uuid: string,
) => {
  const URL = `${orgURL}operations/driveactions/${drive_action_uuid}/controls/${acr_uuid}/history?page_size=150&page_number=1`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const setImplement = async (
  orgURL: string,
  tractor_id: number,
  implementName: string,
) => {
  const URL = `${orgURL}tractor/${tractor_id}/attached-implement`;
  const Response = await axios.put(
    URL,
    {
      attached_implement: implementName,
    },
    {
      headers: await getHeaders(),
    },
  );
  return Response.data;
};

export const getlaborInBarChjarts = async (
  orgURL: string,
  orgId: any,
  day: any,
) => {
  const URL = `${orgURL}organization/${orgId}/bar_chart/labor_costs/prev_days/${day}`;
  // if (orgId === 2) {
  //   URL = `https://4sdguy2cn2.execute-api.us-west-2.amazonaws.com/api/organization/${orgId}/bar_chart/labor_costs/prev_days/${day}`;
  // }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getEnergyChartData = async (
  orgURL: string,
  orgId: any,
  day: any,
) => {
  const URL = `${orgURL}organization/${orgId}/fleet/analytics/chart/cost-explorer/energy-cost?prev_days=${day}`;
  // if (orgId === 2) {
  //   URL = `https://4sdguy2cn2.execute-api.us-west-2.amazonaws.com/api/organization/${orgId}/bar_chart/electricity_costs/prev_days/${day}`;
  // }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTractorEnergyChartData = async (
  orgURL: string,
  orgId: number,
  date: any,
) => {
  const URL = `${orgURL}organization/${orgId}/fleet/analytics/chart/cost-explorer/tractor-energy-cost?from_date_time=${date.startDate}&to_date_time=${date.endDate}`;
  // if (orgId === 2) {
  //   URL = `https://4sdguy2cn2.execute-api.us-west-2.amazonaws.com/api/organization/${orgId}/bar_chart/electricity_costs/prev_days/${day}`;
  // }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const saveVineRowsFromJumpAndGoData = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/planner/vinerow/auto-generation`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const saveVineRowsFromSurveyData = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-row-survey/auto-generate`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getPreviewSaveVineRowsFromSurveyData = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-row-survey/auto-generate/preview`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveAbLinesFromSurveyData = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/auto-generate`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getPreviewSaveAbLineFromSurveyData = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/auto-generate/preview`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTeamsList = async (
  orgURL: string,
  orgId: any,
  pageNumber?: number,
  search?: string | undefined,
) => {
  let URL = `${orgURL}organization/${orgId}/auth/team?`;
  if (pageNumber) {
    URL += `page_number=${pageNumber}&page_size=25`;
  } else {
    URL += `page_number=${pageNumber}&page_size=500`;
  }
  if (search && search?.trim() != '') {
    URL += `&q=${encodeURI(search)}`;
  }

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const addTeam = async (
  orgURL: string,
  orgId: any,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgId}/auth/team`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTeamById = async (
  orgURL: string,
  orgId: any,
  teamId: number,
) => {
  const URL = `${orgURL}organization/${orgId}/auth/team/${teamId}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTeamPriveleges = async (
  orgURL: string,
  orgId: any,
  teamId: number,
) => {
  const URL = `${orgURL}organization/${orgId}/auth/team/${teamId}/privileges`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTeamUsers = async (
  orgURL: string,
  orgId: any,
  teamId: number,
  belongs: boolean,
  search?: string | undefined,
) => {
  let URL = `${orgURL}organization/${orgId}/auth/team/${teamId}/users?belongs_to_team=${belongs}`;
  if (search && search?.trim() != '') {
    URL += `&q=${encodeURI(search)}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getOrgUsers = async (
  orgURL: string,
  orgId: any,
  search?: string | undefined,
) => {
  let URL = `${orgURL}organization/${orgId}/user/dropdown`;
  if (search && search?.trim() != '') {
    URL += `&q=${search}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updateTeamPrivileges = async (
  orgURL: string,
  orgId: any,
  teamId: number,
  data: PrivilegeRequest[],
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgId}/auth/team/${teamId}/privileges`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const addUsersToTeam = async (
  orgURL: string,
  orgId: any,
  teamId: number,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgId}/auth/team/${teamId}/users`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateTeam = async (
  orgURL: string,
  orgId: any,
  teamId: number,
  data: teamUpdateRequest,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgId}/auth/team/${teamId}`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const removeTeam = async (
  orgURL: string,
  orgId: any,
  teamId: number,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${orgId}/auth/team/${teamId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const removeTeamMember = async (
  orgURL: string,
  orgId: any,
  teamId: number,
  userId: any,
) => {
  const url = `${orgURL}organization/${orgId}/auth/team/${teamId}/users/${userId}`;
  const response = await axios.put(
    url,
    {},
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getUserPermission = async (
  orgURL: string,
  orgId: any,
  userId: any,
) => {
  const url = `${orgURL}organization/${orgId}/user/${userId}/auth/privileges`;
  const Response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getBatteryDetailsForTractor = async (
  orgURL: string,
  orgId: any,
  tractorId: any,
) => {
  const url = `${orgURL}organization/${orgId}/tractor/${tractorId}/battery`;
  const Response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const duplicateAllTeam = async (
  orgURL: string,
  orgId: any,
  data: any,
) => {
  const url = `${orgURL}organization/${orgId}/auth/team`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTrainingList = async (
  orgURL: string,
  org_id: number,
  user_id: number,
) => {
  const URL = `${orgURL}organization/${org_id}/user/${user_id}/trainings`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const AddNewEmployee = async (orgURL: string, payload: any) => {
  const url = `${orgURL}user/teams/atom`;
  const Response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const getTrainingDataById = async (
  orgURL: string,
  training_id: number,
) => {
  const URL = `${orgURL}training/${training_id}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const UpdateNewEmployee = async (
  orgURL: string,
  empid: any,
  payload: any,
) => {
  const url = `${orgURL}user/teams/atom/${empid}`;
  const Response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updateTraining = async (
  orgURL: string,
  training_id: number,
  payload: any,
): Promise<TaskResponse> => {
  const url = `${orgURL}training/${training_id}`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getChapterTopics = async (
  orgUrl: string,
  chapterId: number,
  errorCodes?: string,
) => {
  let url = `${orgUrl}repairmanual/chapter/${chapterId}/topic?`;
  if (errorCodes && errorCodes?.trim() !== '') {
    url += `indicator_error_code=${errorCodes}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSubTopicsData = async (
  orgUrl: string,
  chapterId: number,
  topicId: number,
  errorCodes?: string,
) => {
  let url = `${orgUrl}repairmanual/chapter/${chapterId}/topic/${topicId}/sub-topic`;
  if (errorCodes && errorCodes?.trim() !== '') {
    url += `indicator_error_code=${errorCodes}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveAbLineData = async (
  orgURL: string,
  farmId: number,
  payload: AbLinePayloadTypesSingle,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveAbLineData1 = async (
  orgURL: string,
  farmId: number,
  payload: AbLinePayloadTypesSingle1,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/auto-generation`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateAbLineData = async (
  orgURL: string,
  farmId: number,
  abLineId: number,
  payload: AbLinePayloadTypesSingle,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/${abLineId}`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAbLineData = async (
  orgURL: string,
  farmId: number,
  page_number?: number,
  page_size?: number,
) => {
  // const url = `${orgURL}farm/${farmId}/cartography/ab-line?fetch_all=true`;
  let url = `${orgURL}farm/${farmId}/cartography/ab-line?`;
  if (page_size) {
    url = url + `&page_size=${page_size}`;
  }
  if (page_number) {
    url = url + `&page_number=${page_number}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAbLineVerticesData = async (
  orgURL: string,
  farmId: number,
  abLineUuid: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/${abLineUuid}/vertices`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteAbLineFun = async (
  orgURL: string,
  farmId: number,
  abLineId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-line/${abLineId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAbBlocksData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-block?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAbBlockVerticesData = async (
  orgURL: string,
  farmId: number,
  genericMapUuid: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-block/${genericMapUuid}/vertices`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const setAbBlock = async (
  orgURL: string,
  farmId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/ab-block`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const updateAbBlockData = async (
  orgURL: string,
  farmId: number,
  abBlockId: number,
  payload: any,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/ab-block/${abBlockId}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const deleteAbBlockFun = async (
  orgURL: string,
  farmId: number,
  abBlockId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-block/${abBlockId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteRouteFun = async (
  orgURL: string,
  farmId: number,
  routeId: number,
) => {
  const url = `${orgURL}farm/${farmId}/route/${routeId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteSurveyVineRowNewFun = async (
  orgURL: string,
  farmId: number,
  surveyUuid: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/survey/${surveyUuid}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getDigitalApps = async (orgUrl: string, orgId: number) => {
  const url = `${orgUrl}organization/${orgId}/third-party-app`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDigiitalAppAccessToken = async (
  orgURL: string,
  orgId: number,
  tpAppId: number,
  payload: any,
) => {
  const url = `${orgURL}organization/${orgId}/third-party-app/${tpAppId}/trimble_access_token`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTrimbleOrganizations = async (
  orgURL: string,
  orgId: number,
  tpAppId: number,
) => {
  const url = `${orgURL}organization/${orgId}/third-party-app/${tpAppId}/organizations`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const syncLandMarks = async (
  orgURL: string,
  orgId: number,
  tpOrgId: number,
  farmId: number,
  payload: any,
) => {
  const url = `${orgURL}organization/${orgId}/third-party-app/${tpOrgId}/${farmId}/sync_landmarks`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTrimbleSyncWayPoints = async (
  orgURL: string,
  orgId: number,
  farmId: number,
  tpAppId: number,
  tpOrgId: number,
) => {
  const url = `${orgURL}organization/${orgId}/third-party-app/${farmId}/${tpAppId}/${tpOrgId}/tp_org_synced_landmarks`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getModalFleetDiagnostics = async (
  orgURL: string,
  tractor_id: number,
  from_date_time: string,
  to_date_time: string,
  page_number: number,
  page_size: number,
  sort?: any,
) => {
  let URL = `${orgURL}tractor/${tractor_id}/indicator/diagnostics?tractor_id=${tractor_id}`;
  if (page_size) {
    URL = URL + `&page_size=${page_size}`;
  }
  if (page_number) {
    URL = URL + `&page_number=${page_number}`;
  }
  if (from_date_time && to_date_time) {
    URL = URL + `&from_date=${from_date_time}&to_date=${to_date_time}`;
  }
  URL = sortKeyWrapper(URL, sort);
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const createSnapshot = async (
  orgURL: string,
  farmId: number,
  tractorId: number,
) => {
  const URL = `${orgURL}farm/${farmId}/tractor/${tractorId}/camera-snapshot`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const publishAbBlockWithId = async (
  orgURL: string,
  farmId: number,
  abBlockId: number,
  payload: any,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/ab-block/${abBlockId}/publish`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveFeedBack = async (orgURL: string, payload: any) => {
  const url = `${orgURL}drivetractor/remoteav/feedback`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const acceptTermsAndCond = async (
  orgUrl: string,
  orgId: number,
  userId: any,
  payload = {},
) => {
  const url = `${orgUrl}organization/${orgId}/user/${userId}/update-compliance-check`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getTermsAndCond = async (orgURL: string, orgId: number) => {
  const URL = `${orgURL}organization/${orgId}/compliance/10001`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getAutoDrivePermissions = async (
  orgURL: string,
  org_id: number,
) => {
  const url = `${orgURL}organization/${org_id}/mos-features`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const ChangeOrgAdmin = async (token: string) => {
  const url = `${BASE_URL}/user/org-admin/change`;
  const response = await axios.put(url, { token }, {});
  return response.data;
};

export const createOrgAdmin = async (payload: any) => {
  const url = `${BASE_URL}/user/org-admin/create`;
  const response = await axios.post(url, payload, {});
  return response.data;
};

export const generateSnapshots = async (orgId: number, payload: any) => {
  const URL = `${BASE_URL}organization/${orgId}/camera-snapshot-images/download`;
  const response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDownloadedSnapshots = async (
  orgURL: string,
  orgId: number,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}organization/${orgId}/camera-snapshot-images/download?page_size=${page_size}&page_number=${page_number}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getLiveVideoStatus = async (orgURL: string, tractorId: number) => {
  const url = `${orgURL}tractor/${tractorId}/pause-live-video-stream`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateLiveVideoStatus = async (
  orgURL: string,
  tractorId: number,
  status: boolean,
) => {
  const url = `${orgURL}tractor/${tractorId}/pause-live-video-stream`;
  const response = await axios.put(
    url,
    {
      pause_live_video_stream: status,
    },
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getKnowledgeBaseList = async (q: any) => {
  const url = `${BASE_URL}/knowledgebase-viewer/view?q=${q}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getKnowledgeBaseTitle = async (q: any) => {
  const url = `${BASE_URL}/knowledgebase-viewer/view/title?title=${q}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFrequentData = async (q: any) => {
  const url = `${BASE_URL}/knowledgebase-viewer/view/frequent-data?frequency=${q}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getOtaLogs = async (
  orgURL: string,
  fleetId: number,
  tractorId: number,
  pageNumber: number,
  pageSize: number,
  status: string,
) => {
  const url = `${orgURL}fleet/${fleetId}/tractor/${tractorId}/software-history?page_number=${pageNumber}&page_size=${pageSize}&status=${status}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOtaLogsDetails = async (
  orgURL: string,
  fleetId: number,
  tractorId: number,
  historyId: number,
  pageNumber: number,
  pageSize: number,
) => {
  const url = `${orgURL}fleet/${fleetId}/tractor/${tractorId}/software-history/${historyId}/details?page_number=${pageNumber}&page_size=${pageSize}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getIncompleteOperations = async (
  orgURL: string,
  farmId: number,
  search?: any,
  sort_column?: any,
  sort_by?: any,
  implement?: string,
) => {
  let url = `${orgURL}drivetractor/remoteav/incomplete-operations?`;

  if (sort_column) {
    url = `${url}&sort_column=${sort_column}`;
  }
  if (sort_by) {
    url = `${url}&sort_by=${sort_by}`;
  }
  if (search) {
    url = `${url}&q=${search}`;
  }

  if (implement && implement !== noneAttached) {
    url = `${url}&implement_name=${implement}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveFarmObservation = async (orgId: number, payload: any) => {
  const URL = `${BASE_URL}/organization/${orgId}/farm-observations`;
  const response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const AddattachmentsFiles = async (
  orgId: number,
  obuid: any,
  payload: any,
) => {
  const URL = `${BASE_URL}/organization/${orgId}/farm-observations/${obuid}/add-attachments`;
  const response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const FarmobservationsList = async (
  orgid: number,
  pageNumber: number,
  pageSize: number,
) => {
  const url = `${BASE_URL}/organization/${orgid}/farm-observations?page_number=${pageNumber}&page_size=${pageSize}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getCompleteOperationPoints = async (
  orgURL: string,
  uuid: string,
) => {
  const url = `${orgURL}drivetractor/remoteav/incomplete-operations/${uuid}/path`;

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const FarmobservationsDetails = async (
  orgid: number,
  observations_uuid: string,
) => {
  const url = `${BASE_URL}/organization/${orgid}/farm-observations/${observations_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSurveyVineRowsData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/survey-vine-row?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSurveyVineRowData = async (
  orgURL: string,
  farmId: number,
  servey_vine_row_uuid: string,
) => {
  const url = `${orgURL}farm/${farmId}/survey-vine-row/${servey_vine_row_uuid}/vertices`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSurveyVineRowsNewData = async (
  orgURL: string,
  farmId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/survey`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSurveyVineRowNewData = async (
  orgURL: string,
  farmId: number,
  servey_vine_row_uuid: string,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/survey/${servey_vine_row_uuid}/points`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUpdatesBasestationList = async (
  orgURL: string,
  org_id: number,
  page_number: number,
  page_size: number,
  search: string,
  sort?: any,
  connection_status?: any,
) => {
  let url = `${orgURL}organization/${org_id}/basestation?page_number=${page_number}&page_size=${page_size}`;
  if (search && search?.trim() != '') {
    url += `&q=${encodeURI(search)}`;
  }
  if (connection_status) {
    url += `&connection_status=${connection_status}`;
  }
  url = sortKeyWrapper(url, sort);
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updateBaseStationSoftwareAPI = async (
  orgUrl: string,
  id: number,
  payload: any,
) => {
  const url = `${orgUrl}fleet/basestation/${id}/software-update`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const saveAssignMethod = async (
  orgUrl: string,
  id: number,
  payload: any,
) => {
  const url = `${orgUrl}tractor/${id}/assign-basestation`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMapMakerList = async (
  orgURL: string,
  id: number,
  pageNumber: number,
  pageSize: number,
  status: string,
) => {
  const url = `${orgURL}organization/${id}/job-manager?category=${status}&page_number=${pageNumber}&page_size=${pageSize}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const uploadVineBlockFileUpload = async (
  orgURL: string,
  farmId: number,
  formData: FormData,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows-upload/auto-generation`;
  const response = await axios.post(url, formData, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMaintenancelist = async (
  orgURL: string,
  id: any,
  pageNumber: number,
  pageSize: number,
  search: any,
  updateStatus: string,
) => {
  let url = `${orgURL}organization/${id}/fleet-maintenance-rule?page_number=${pageNumber}&page_size=${pageSize}`;
  if (search && search?.trim() != '') {
    url += `&q=${encodeURI(search)}`;
  }
  if (updateStatus) {
    url += `&status=${updateStatus}`;
  }
  const Response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getfleetmaintenancerulelist = async (
  orgURL: string,
  orgid: number,
  id: number,
) => {
  const URL = `${orgURL}organization/${orgid}/fleet-maintenance-rule/${id}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const Updatefleetmaintenancerule = async (
  orgURL: string,
  orgid: number,
  id: number,
  payload: any,
) => {
  const URL = `${orgURL}organization/${orgid}/fleet-maintenance-rule/${id}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const deleteSurveyVineRowFun = async (
  orgURL: string,
  farmId: number,
  surveyId: number,
) => {
  const url = `${orgURL}farm/${farmId}/survey-vine-row/${surveyId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const deletePolyLineFun = async (
  orgURL: string,
  farmId: number,
  polyLineId: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/polylines/${polyLineId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSurveysBasedOnIds = async (
  orgURL: string,
  org_id: number,
  farmId: number,
  survey_row_ids: number[],
) => {
  const url = `${orgURL}organization/${org_id}/farm/${farmId}/survey-vine-row/download-csv?survey_row_ids=${survey_row_ids}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
    responseType: 'blob',
  });
  return response;
};

export const getCopycatplanner = async (
  orgURL: string,
  id: number,
  tid: number,
  starttime: any,
  endtime: any,
) => {
  const URL = `${orgURL}farm/${id}/analytics/controls/videos-urls?tractor_id=${tid}&from_date_time=${starttime}&to_date_time=${endtime}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTicketsSummary = async (orgURL: string, fleetId: number) => {
  const URL = `${orgURL}fleet/${fleetId}/maintenance-log/tickets-summary`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getImplementsSummary = async (orgURL: string, fleetId: number) => {
  const URL = `${orgURL}fleet/${fleetId}/config/implements/summary`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getptoRpmList = async (orgURL: string, pto_rpm: number) => {
  const URL = `${orgURL}implements/tractor-speed-by-pto-rpm?pto_rpm=${pto_rpm}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getSupportRoom = async (
  orgURL: string,
  organization_id: number,
  user_id: number,
) => {
  const URL = `${orgURL}converse/organization/${organization_id}/user/${user_id}/support-room`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const createSupportRoom = async (
  orgURL: string,
  organizationId: number,
  userId: number,
  payload: any,
) => {
  const url = `${orgURL}converse/organization/${organizationId}/user/${userId}/room/create`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getConverseHistory = async (
  orgURL: string,
  organizationId: number,
  userId: number,
  room_uuid: number,
) => {
  const url = `${orgURL}converse/organization/${organizationId}/user/${userId}/room/${room_uuid}/chat-history`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const closeRoom = async (
  orgURL: string,
  organizationId: number,
  userId: number,
  room_uuid: number,
  payload: any,
) => {
  const URL = `${orgURL}converse/organization/${organizationId}/user/${userId}/room/${room_uuid}/close`;
  const response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getGuardRailsImages = async (
  orgURL: string,
  farmId: number,
  guardrails_uuid: string,
) => {
  const url = `${orgURL}farm/${farmId}/snapshot/image-url?category=guardrails&guardrails_uuid=${guardrails_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteBulkVineRows = async (
  orgURL: string,
  farmId: number,
  payload: any,
): Promise<TaskResponse> => {
  const url = `${orgURL}farm/${farmId}/cartography/vine-rows/bulk-delete`;
  const response = await axios.put(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getRoomsHistoryList = async (
  orgURL: string,
  organizationId: number,
  userId: any,
) => {
  const url = `${orgURL}converse/organization/${organizationId}/user/${userId}/room?room_status=CLOSED`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getLookpsList = async (orgURL: string, name: string) => {
  const url = `${orgURL}operations-planner/lookup/${name}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const SaveOperationsPlanner = async (
  orgURL: string,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}operations-planner/event`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUpdatesCount = async (
  orgURL: string,
  organizationId: number,
) => {
  const url = `${orgURL}organization/${organizationId}/tractor-software-update-count`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getChargePoints = async (
  orgURL: string,
  organizationId: number,
  page_number: number,
  page_size: number,
  search?: any,
) => {
  let URL = `${orgURL}organization/${organizationId}/charge-points?page_number=${page_number}&page_size=${page_size}`;
  if (search) {
    URL = `${URL}&q=${search}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const createChargePoint = async (
  orgURL: string,
  organizationId: number,
  payload: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${organizationId}/charge-points`;
  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getChargePointById = async (
  orgURL: string,
  organizationId: number,
  charge_point_id: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}organization/${organizationId}/charge-points/${charge_point_id}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getElectricityPlan = async (
  orgURL: string,
  organizationId: number,
) => {
  const URL = `${orgURL}organization/${organizationId}/electricity-rate-plan`;

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const UpdateChargePoint = async (
  orgURL: string,
  organizationId: number,
  chargePointId: number,
  payload: any,
) => {
  const URL = `${orgURL}organization/${organizationId}/charge-points/${chargePointId}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getChargePointDetails = async (
  orgURL: string,
  organizationId: number,
  chargePointId: number,
) => {
  const URL = `${orgURL}organization/${organizationId}/charge-points/${chargePointId}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const deleteChargePoint = async (
  orgURL: string,
  organizationId: number,
  chargePointId: number,
) => {
  const url = `${orgURL}organization/${organizationId}/charge-points/${chargePointId}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getPolyLineData = async (orgURL: string, farmId: number) => {
  const url = `${orgURL}farm/${farmId}/cartography/polylines?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getPolyLineVerticesData = async (
  orgURL: string,
  farmId: number,
  polyLineUuid: number,
) => {
  const url = `${orgURL}farm/${farmId}/cartography/polylines/${polyLineUuid}/vertices`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updatePolyLine = async (
  orgURL: string,
  farmId: number,
  payload: any,
  polyLineUuid: string,
) => {
  const URL = `${orgURL}farm/${farmId}/cartography/polylines/${polyLineUuid}`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getBaseStationIndicatorSummaryData = async (
  orgURL: string,
  base_id?: number,
) => {
  const url = ` ${orgURL}basestation/${base_id}/indicator/summary`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBaseStationDiagnostics = async (
  orgURL: string,
  page_number: number,
  page_size: number,
  search: string,
  sort?: any,
  fromDateTime?: any,
  toDateTime?: any,
  status?: any,
  level?: any,
  indicatorType?: any,
  subSystem?: any,
  errocode?: any,
  base_id?: number,
) => {
  let URL = ` ${orgURL}basestation/${base_id}/indicator/diagnostics`;
  if (status) {
    URL = URL + `?indicator_history_status=${status}`;
  }
  if (page_number) {
    URL = URL + `&page_number=${page_number}`;
  }
  if (page_size) {
    URL = URL + `&page_size=${page_size}`;
  }
  if (level) {
    URL = URL + `&indicator_level=${level}`;
  }
  if (subSystem) {
    URL = URL + `&sub_system=${subSystem}`;
  }
  if (indicatorType) {
    URL = URL + `&indicator_key=${indicatorType}`;
  }
  if (errocode) {
    URL = URL + `&error_code=${errocode}`;
  }
  if (fromDateTime && toDateTime) {
    URL = URL + `&from_date=${fromDateTime}&to_date=${toDateTime}`;
  }
  if (search.trim() != '') URL += `&q=${search}`;
  URL = sortKeyWrapper(URL, sort);
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getBaseOrgErrorCodesData = async (orgURL: string) => {
  const url = `${orgURL}basestation/diagnosis-codes?all_error_codes=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBaseOrgSubsystemsData = async (orgURL: string) => {
  const url = `${orgURL}basestation/diagnosis-codes?all_subsystem=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBaseStationDiagnosticsList = async (
  orgURL: string,
  org_id: number,
) => {
  const url = `${orgURL}organization/${org_id}/basestation/dropdown`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBasestationOTALogs = async (
  orgURL: string,
  fleetId: number,
  basestationId: number,
  pageNumber: number,
  pageSize: number,
  status: string,
) => {
  const url = `${orgURL}fleet/${fleetId}/basestation/${basestationId}/software-history?page_number=${pageNumber}&page_size=${pageSize}&status=${status}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBasestationOTALogsHistory = async (
  orgURL: string,
  fleetId: number,
  basestationId: number,
  historyId: number,
) => {
  const url = `${orgURL}fleet/${fleetId}/basestation/${basestationId}/software-history/${historyId}/details?fetch_all=true`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deleteUnfinishedRouteFun = async (
  orgURL: string,
  incompleteOperationUuid: number,
) => {
  const url = `${orgURL}drivetractor/remoteav/incomplete-operations/${incompleteOperationUuid}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorAudioFiles = async (
  orgURL: string,
  fleetId: number,
  tractorId: number,
  audio_time: number,
) => {
  const url = `${orgURL}fleet/${fleetId}/analytics/audios-url?audio_time=${audio_time}&tractor_id=${tractorId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUpdatesList = async (
  orgURL: string,
  page_number: number,
  page_size: number,
) => {
  const URL = `${orgURL}fleet-ota/fleet-software-list?page_number=${page_number}&page_size=${page_size}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getOtaUpdate = async (orgURL: string) => {
  const URL = `${orgURL}fleet-ota/fleet-ota-update`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getUpdateSummary = async (orgURL: string) => {
  const URL = `${orgURL}fleet-ota/fleet-ota-summary`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const otaSchedule = async (orgURL: string, payload: any) => {
  const URL = `${orgURL}fleet-ota/schedule`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const otaInstall = async (orgURL: string, payload: any) => {
  const URL = `${orgURL}fleet-ota/installation`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getSelectedTractors = async (orgURL: string, tractorIds: any) => {
  const URL = `${orgURL}fleet-ota/fleet-ota-update?tractor_ids=${tractorIds}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const cancelAutomatedReminders = async (
  orgURL: string,
  payload: any,
) => {
  const URL = `${orgURL}organization/ticket-reminder/cancel`;
  const Response = await axios.post(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const deleteInactive = async (orgURL: string, id: number) => {
  const url = `${orgURL}user/in-active/${id}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const confirmUserNamechange = async (payload: any) => {
  const URL = `${BASE_URL}/user/update-email/confirm`;
  const Response = await axios.put(URL, payload);
  return Response.data;
};

export const getRadioData = async (orgURL: string, tractor_id: number) => {
  const URL = `${orgURL}tractor/${tractor_id}/hb-over-radio`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const enableDisableHbRadio = async (
  orgURL: string,
  tractor_id: number,
  payload: any,
) => {
  const URL = `${orgURL}tractor/${tractor_id}/hb-over-radio`;
  const Response = await axios.put(URL, payload, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getPresetOperationsData = async (
  orgURL: string,
  implementUuid: string,
) => {
  const URL = `${orgURL}autodrive/presets?implement_uuid=${implementUuid}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const savePreset = async (orgURL: string, data: any) => {
  const url = `${orgURL}autodrive/presets`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updatePresetOrder = async (
  orgURL: string,
  data: any,
): Promise<{ msg: string }> => {
  const url = `${orgURL}autodrive/presets/sortingorder`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const updatePreset = async (
  orgURL: string,
  data: any,
  presetUuid: string,
): Promise<{ msg: string }> => {
  const url = `${orgURL}autodrive/presets/${presetUuid}`;
  const response = await axios.put(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const deletePreset = async (
  orgURL: string,
  presetUuid: string,
): Promise<{ msg: string }> => {
  const url = `${orgURL}autodrive/presets/${presetUuid}`;
  const response = await axios.delete(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const favaratePreset = async (
  orgURL: string,
  data: any,
  presetUuid: string,
) => {
  const url = `${orgURL}autodrive/presets/${presetUuid}/favourite`;
  const response = await axios.post(url, data, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const approvedImmList = async (
  orgURL: string,
  implement_type: string,
  manufacturer: string,
  is_autonomy_implement: boolean,
  attachment_type: string,
  search: string,
) => {
  const URL = `${orgURL}fleet/config/global-approved/imm?implement_type=${implement_type}&manufacturer=${manufacturer}&is_autonomy_implement=${is_autonomy_implement}&attachment_type=${attachment_type}&q=${search}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getHistoryData = async (
  orgURL: string,
  org_id: number,
  tractor_id: number,
  start_date: any,
  end_date: any,
  pageNumber: number,
  pageSize: number,
) => {
  let URL = `${orgURL}data-prioritization?org_ids=${org_id}&page_number=${pageNumber}&page_size=${pageSize}`;
  if (tractor_id) {
    URL = URL + `&tractor_ids=${tractor_id}`;
  }
  if (start_date) {
    URL = URL + `&start_date=${start_date}`;
  }
  if (end_date) {
    URL = URL + `&end_date=${end_date}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getHistoryDetails = async (
  orgURL: string,
  consumed_date: any,
  pageNumber: number,
  pageSize: number,
) => {
  const URL = `${orgURL}data-prioritization/hour-wise-data-prioritization?consumed_date=${consumed_date}&page_number=${pageNumber}&page_size=${pageSize}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTireInformation = async (
  orgURL: string,
  serial_number: string,
) => {
  const URL = `${orgURL}tractor/serial-number/${serial_number}/tire-details`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getOperationalDriveActionPath = async (
  orgURL: string,
  driveAction: any,
) => {
  const url = `${orgURL}analytics/drive-actions/${driveAction}/travelled-path-by-planner`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
