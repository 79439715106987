import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { track } from '../../util/logger';
import AnalyticsDatabase from './Database/AnalyticsDatabase';
import AnalyticsContextProvider from './AnalyticsContext';
import Library from '../library/Library';
import ReportsDistribution from '../reports/ReportsDistrbution';
import FleetUsageCharts from '../fleetUsageBarCharts/FleetUsageCharts';
import { DriveAction } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { SET_JAG_DACTION, SET_LAST_TAB_INDEX } from '../../context/actions';
import AnalyticsWrapper from './Database/AnalyticsWrapper';
import AnalyticsDetails from './Database/AnalyticsDetails';
import FarmObservations from '../common/FarmObservations/FarmObservations';
import { checkAlphaFeature } from '../../constants/Common';
import { envVar } from '../../constants/constant';
import './style.css';
import './map-style.css';
import ImpactDashboard from '../admin/impactDashboard/ImpactDashboard';
import OperationalAnalyticsContainer from '../operationalAnalyticsContainer';

const { TabPane } = Tabs;
const AnalyticContainer: React.FC = () => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : {};
  const [tabid, setTabid] = useState<any>('Operations');
  const [transactionId, setTransactionId] = useState('');
  const [showLiveMap, setShowLiveMap] = useState<boolean>(false);
  const [driveAction, setDriveAction] = useState<DriveAction | null>(null);
  const { APPReducer, userDetails } = useContext(ApplicationContext);
  const [tstate, dispatch] = APPReducer;
  const { routeRefresh, jAGDAction } = tstate;

  useEffect(() => {
    if (jAGDAction) {
      setTabid('Operations');
      if (jAGDAction) {
        setDriveAction(jAGDAction);
        setShowLiveMap(true);
      }
    }
    return () => {
      window.history.replaceState({}, document.title);
      setDriveAction(null);
      dispatch({
        type: SET_JAG_DACTION,
        payload: null,
      });
    };
  }, [routeRefresh]);

  useEffect(() => {
    if (state && state.action && state.action === 'Operations') {
      setTabid(state.action);
      if (state.transactionId && state.transactionId.trim() !== '') {
        setTransactionId(state.transactionId);
      }
    }
    return () => {
      window.history.replaceState({}, document.title);
      setTransactionId('');
    };
  }, [state.action, state.drive_action]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'Analytics') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('Operations');
      // setTabid('Library');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  useEffect(() => {
    track('Analytics', {
      event: 'Entered Analytics',
      start: true,
    });
    return () => {
      track('Analytics', {
        event: 'Exit from Analytics',
        start: false,
      });
    };
  }, []);

  return (
    <AnalyticsContextProvider>
      <div className="rightSideContainer">
        <div style={{ display: showLiveMap ? 'none' : 'block' }}>
          <Tabs
            activeKey={tabid}
            centered
            id="analytics-tab-AnalyticContainer"
            // className={
            //   tabid && tabid === '1' ? 'elTabs elTabs1' : 'elTabs'
            // }
            className="common_tabs implementTab opAnalyticsTabs"
            onChange={(key) => {
              setTabid(key);
              dispatch({
                type: SET_LAST_TAB_INDEX,
                payload: {
                  catlog: 'Analytics',
                  tab: key,
                },
              });
              track(key, {
                event: `${key} Tab`,
              });
            }}
          >
            <TabPane
              tab="Operations"
              key="Operations"
              className="attTableHeight mBottom0 elTabs1"
              style={{ top: -50 }}
            >
              <AnalyticsDatabase
                toggleLiveMap={() => setShowLiveMap(!showLiveMap)}
              />
              {/* <OperationalAnalyticsContainer /> */}
            </TabPane>
            {userDetails?.organization &&
              process.env.REACT_APP_IMPACT_DASHBOARD &&
              process.env.REACT_APP_IMPACT_DASHBOARD.includes(
                String(userDetails.organization.id),
              ) && (
                <TabPane
                  tab="impact dashboard"
                  key="impact dashboard"
                  className="attTableHeight mBottom0 elTabs1"
                  style={{ top: -50 }}
                >
                  <ImpactDashboard />
                </TabPane>
              )}

            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('CostExplorer') && (
                <TabPane
                  tab="Cost explorer"
                  key="Cost explorer"
                  className="attTableHeight mBottom0"
                >
                  <FleetUsageCharts />
                </TabPane>
              )}
            {checkAlphaFeature(envVar.FarmObservations) && (
              <TabPane
                tab="Farm Observation"
                key="Farm Observation"
                className="attTableHeight mBottom0"
              >
                <FarmObservations />
              </TabPane>
            )}
            <TabPane
              tab="Library"
              key="Library"
              className="attTableHeight mBottom0"
            >
              <Library transactionId={transactionId} />
            </TabPane>

            <TabPane
              tab="Reports"
              key="Reports"
              className="attTableHeight mBottom0"
            >
              <ReportsDistribution />
            </TabPane>
          </Tabs>
        </div>
        {!driveAction && (
          <AnalyticsWrapper
            setShowLiveMap={setShowLiveMap}
            showLiveMap={showLiveMap}
          />
        )}
        {driveAction && (
          <AnalyticsDetails
            driveAction={driveAction}
            setShowLiveMap={setShowLiveMap}
            setDriveAction={setDriveAction}
          />
        )}
      </div>
    </AnalyticsContextProvider>
  );
};

export default AnalyticContainer;
