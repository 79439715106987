import { create } from 'zustand';

interface OperationalHours {
  hours: number;
  minutes: number;
}

interface AnalyticDetails {
  tractorId: string;
  implementName: string;
  operators: any[];
  operationHours: {
    manual: OperationalHours;
    idle: OperationalHours;
    opAssist: OperationalHours;
    autoDrive: OperationalHours;
  };
  totalOperationalHours: OperationalHours;
  efficiency: string;
  averageSpeed: string;
  coverage: string;
  distance: string;
}

// define types for state values and actions separately
type State = {
  ticketsData: any[];
  analyticDetails: AnalyticDetails;
  operationalHours: any[];
  selectedTractor: any;
  operations: any[];
};

type Actions = {
  addTicketsData: (data: any[]) => void;
  addAnalyticsDetails: (data: any) => void;
  addOperationalHoursData: (data: any[]) => void;
  selectTractor: (tractor: any) => void;
  selectOperations: (operations: any) => void;
  reset: () => void;
};

const opHrs = {
  hours: 0,
  minutes: 0,
};

// define the initial state
const initialState: State = {
  ticketsData: [],
  analyticDetails: {
    tractorId: '',
    implementName: '',
    operators: [],
    operationHours: {
      manual: opHrs,
      idle: opHrs,
      opAssist: opHrs,
      autoDrive: opHrs,
    },
    totalOperationalHours: opHrs,
    efficiency: '',
    averageSpeed: '',
    coverage: '',
    distance: '',
  },
  operationalHours: [],
  selectedTractor: null,
  operations: [],
};

const useOperationalAnalyticDetailsStore = create<State & Actions>()(
  (set, get) => ({
    ...initialState,
    addTicketsData: (ticketsData) =>
      set((state) => ({
        ticketsData,
      })),
    addAnalyticsDetails: (analyticDetails) =>
      set((state) => ({
        analyticDetails,
      })),
    addOperationalHoursData: (operationalHours) =>
      set((state) => ({
        operationalHours,
      })),
    selectTractor: (selectedTractor) =>
      set((state) => ({
        selectedTractor,
      })),
    selectOperations: (operations) =>
      set((state) => ({
        operations,
      })),
    reset: () => {
      set(initialState);
    },
  }),
);

export default useOperationalAnalyticDetailsStore;
