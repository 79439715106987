import { create } from 'zustand';

interface OperationalHours {
  hours: number;
  minutes: number;
}

interface CoverageByOperations {
  category: string;
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  total: number;
}

interface NoImplementSummary {
  avgSpeed: string;
  distance: string;
  efficiency: string;
}

// define types for state values and actions separately
type State = {
  operationalHours: OperationalHours;
  coverage: string;
  completedWork: any[];
  energyCostSavingsData: any[];
  implementTypeEfficiencyData: any;
  totalSavings: string;
  noImplementSummary: NoImplementSummary;
  operationalHoursByOperations: any[];
  coverageByOperations: any[];
  operationsLoader: boolean;
  co2Savings: string;
};

type Actions = {
  addOperationalHours: (operationalHours: OperationalHours) => void;
  addCoverage: (coverage: any) => void;
  addCompletedWorkData: (completedWork: any[]) => void;
  addEnergyCostSavings: (energyCostSavingsData: any[]) => void;
  addImplementTypeEfficiencyData: (
    efficiencyData: any,
    totalEfficiency: string,
    totalCoverage: string,
  ) => void;
  addtotalSavings: (totalSavings: any) => void;
  addCo2Savings: (savings: string) => void;
  addNoImplementSummary: (data: NoImplementSummary) => void;
  addCoverageByOperations: (
    coverageByOperations: CoverageByOperations[],
  ) => void;
  addOperationalHoursByOperations: (
    operationalHoursByOperations: any[],
  ) => void;
  updateOperationsLoader: (data: boolean) => void;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  operationalHours: {
    hours: 0,
    minutes: 0,
  },
  coverage: '0',
  completedWork: [],
  implementTypeEfficiencyData: null,
  energyCostSavingsData: [],
  totalSavings: '0',
  co2Savings: '0',
  noImplementSummary: {
    avgSpeed: '0',
    distance: '0',
    efficiency: '0',
  },
  operationalHoursByOperations: [],
  coverageByOperations: [],
  operationsLoader: false,
};

const useOperationalAnalyticDetailsStore = create<State & Actions>()(
  (set, get) => ({
    ...initialState,
    addOperationalHours: (operationalHours) =>
      set((state) => ({ operationalHours })),
    addCoverage: (coverage) =>
      set((state) => ({
        coverage,
      })),
    addCompletedWorkData: (completedWork) =>
      set((state) => ({
        completedWork,
      })),
    addEnergyCostSavings: (energyCostSavingsData) =>
      set((state) => ({
        energyCostSavingsData,
      })),
    addtotalSavings: (totalSavings) =>
      set((state) => ({
        totalSavings,
      })),
    addCo2Savings: (co2Savings) =>
      set((state) => ({
        co2Savings,
      })),
    addImplementTypeEfficiencyData: (
      efficiencyData,
      totalEfficiency,
      totalCoverage,
    ) =>
      set((state) => ({
        implementTypeEfficiencyData: {
          efficiencyData,
          totalEfficiency,
          totalCoverage,
        },
      })),
    addNoImplementSummary: (noImplementSummary) =>
      set((state) => ({
        noImplementSummary,
      })),
    addOperationalHoursByOperations: (operationalHoursByOperations) =>
      set((state) => ({
        operationalHoursByOperations,
      })),
    addCoverageByOperations: (coverageByOperations) =>
      set((state) => ({
        coverageByOperations,
      })),
    updateOperationsLoader: (operationsLoader) =>
      set((state) => ({
        operationsLoader,
      })),
    reset: () => {
      set(initialState);
    },
  }),
);

export default useOperationalAnalyticDetailsStore;
