/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../../locale/en_translate.json';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import usePaginate from '../../../../hooks/usePaginate';
import { ApplicationContext } from '../../../../context/AppContext';
import {
  getStatusForTractor,
  getTractorStatusClass,
  initScroller,
} from '../../../../constants/Common';
import {
  getOrgDropdown,
  getTractorHealthSummary,
  getTractorsData,
} from '../../api';
import { useTranslation } from 'react-i18next';
import { mapTractorHealthData, tractorStatusSort } from '../../Common';
import CustomMultiSelect from '../../../common/CustomMultiSelect';
import tractorsDown from '../../../../assets/images/tractor_down.svg';
import tractorsNotUsed from '../../../../assets/images/tractor_not_being_used.svg';
import tractorsOnline from '../../../../assets/images/tractor_online.svg';
import tractorsSemiFunctional from '../../../../assets/images/tractor_semi_functional.svg';
import totlaTractors from '../../../../assets/images/tractors.svg';
import tractorsOffline from '../../../../assets/images/tractors_offline.svg';
import CustomSelect from '../../../common/CustomSelect';
import copyIcon from '../../../../assets/images/copy_icon.png';

const TractorHealth = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any>('');
  const [connection, setConnection] = useState<any>('online');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const connectionData = [
    { name: 'Online', value: 'online' },
    { name: 'Offline', value: 'offline' },
  ];
  const wrapResult = async (
    orgUrl: any,
    orgId: number,
    orgData: any,
    pageNumber: number,
    pageSize: number,
    connection: any,
    search: string,
  ) => {
    const result = await getTractorsData(
      orgUrl,
      orgId,
      orgData,
      pageNumber,
      pageSize,
      connection,
      search,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getAllOrganizations = async () => {
    try {
      const { api_url } = userDetails.organization;
      const result = await getOrgDropdown(api_url);
      const orgList = result.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      const defaultSelectedIds = result?.map((item: any) => String(item.id));
      setSelectedOrgId(defaultSelectedIds);
      setOrganizations(orgList);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };
  const getTractorsList = async (orgData: any, page_number: any) => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        userDetails?.organization.api_url,
        userDetails.organization_id,
        orgData,
        page_number,
        pageSize,
        connection,
        search,
      );

      if (searchString.current !== searchKey) return;

      // eslint-disable-next-line no-underscore-dangle
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      const updatedData = mapTractorHealthData(data, orgData);
      const tdata = tractorStatusSort(updatedData);

      setData(tdata);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1
        ? setPageNumber(1)
        : getTractorsList(selectedOrgId, pageNumber);
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList(selectedOrgId, pageNumber);
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (search !== '') {
      setPageNumber(1);
    }
    if (userDetails && userDetails.organization) {
      loadTractors();
    }
  }, [userDetails, pageNumber, search]);

  useEffect(() => {
    if (selectedOrgId.length === 0) {
      setData([]);
      setTotal(0);
    } else {
      activity.current = 'filter';
      loadTractors();
    }
  }, [selectedOrgId, connection]);

  useEffect(() => {
    if (userDetails?.organization) getAllOrganizations();
  }, [userDetails]);

  const getHealthSummary = async () => {
    try {
      const { organization } = userDetails;
      const tractorSummary = await getTractorHealthSummary(
        organization.api_url,
        selectedOrgId,
      );
      setSummary(tractorSummary);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedOrgId.length > 0) {
      getHealthSummary();
    } else {
      setSummary('');
    }
  }, [selectedOrgId, userDetails]);

  const columnsData = [
    {
      title: t(translate.healthData.name),
      dataIndex: 'name',
      key: 'name',
      width: '195px',
      render: (name: string) => (
        <span data-testid={`${name}-TractorHealth`}>{name}</span>
      ),
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: t(translate.healthData.tpin),
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      width: '200px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),

      render: (tractor_pin: string) => (
        <span className="emailAlign hoverWrapper">
          <span className="tractorPinStyle">{tractor_pin}</span>
          <img
            src={copyIcon}
            alt="Edit"
            height="32"
            width="32"
            className="marginLeftIcon hoverShow1 on-hover-hand-change"
            onClick={() => {
              navigator.clipboard.writeText(tractor_pin);
              notification.info({ message: 'Copied' });
            }}
          />
        </span>
      ),
    },
    {
      title: `${t(translate.healthData.customerName)}`,
      dataIndex: 'orgName',
      key: 'orgName',
      width: '200px',
      ellipsis: true,
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.healthData.soc)}`,
      dataIndex: 'soc',
      key: 'soc',
      width: '120px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.healthData.status)}`,
      dataIndex: 'jng',
      key: 'jng',
      width: '150px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
      render: (jng: any, record: any) => {
        const { isAvailable } = record;
        return (
          <span
            className={getTractorStatusClass(isAvailable)}
            data-testid={`${jng}-${record.name}-TractorHealth`}
          >
            {getStatusForTractor(isAvailable)}
          </span>
        );
      },
    },
    {
      title: `${t(translate.healthData.tractorHours)}`,
      dataIndex: 'tractor_hours',
      key: 'tractor_hours',
      width: '140px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
      render: (tractor_hours: string, record: any) => (
        <span data-testid={`${tractor_hours}-${record.name}-TractorHealth`}>
          {tractor_hours === 'NaN' ? '0' : tractor_hours}
        </span>
      ),
    },
    {
      title: `${t(translate.healthData.software)}`,
      dataIndex: 'software_version',
      key: 'software_version',
      width: '150px',
      render: (software_version: string) => <>{software_version || '-'} </>,
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.healthData.firmware)}`,
      dataIndex: 'firmware_version',
      key: 'firmware_version',
      width: '150px',
      render: (firmware_version: string) => <>{firmware_version || '-'} </>,
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.healthData.dateAndtime)}`,
      dataIndex: 'date_time',
      key: 'date_time',
      width: '180px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
  ];
  const handelConnection = (value: any) => {
    setConnection(value);
  };
  const getRow = (record: any) => {
    console.log(record, 'bsndvdvvh');
  };
  return (
    <Layout className="posRel">
      <Content>
        <div className="gridTabsContainer">
          <div className="gridItem tractorHlth">
            <img src={totlaTractors} alt="total Tractors" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Tractors</div>
              <div className="assignNum">
                {summary?.total_no_of_tractors || 0}
              </div>
            </div>
          </div>
          <div className="gridItem tractorHlth">
            <img src={tractorsOnline} alt="Online Tractors" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Online</div>
              <div className="assignNum">
                {' '}
                {summary?.no_of_online_tractors || 0}
              </div>
            </div>
          </div>
          <div className="gridItem tractorHlth">
            <img src={tractorsOffline} alt="Offline Tractors" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Offline</div>
              <div className="assignNum">
                {' '}
                {summary?.no_of_offline_tractors || 0}
              </div>
            </div>
          </div>
          <div className="gridItem tractorHlth">
            <img src={tractorsDown} alt="Down Tractors" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Down</div>
              <div className="assignNum">
                {' '}
                {summary?.no_of_tractors_down || 0}
              </div>
            </div>
          </div>
          <div className="gridItem tractorHlth">
            <img src={tractorsSemiFunctional} alt="Tractors Semi Functional" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Semi Functional</div>
              <div className="assignNum">
                {' '}
                {summary?.no_of_tractors_semi_functional || 0}
              </div>
            </div>
          </div>
          <div className="gridItem tractorHlth">
            <img src={tractorsNotUsed} alt="Tractors Not Used" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">Not Being Used</div>
              <div className="assignNum">
                {' '}
                {summary?.no_of_tractors_rarely_used || 0}
              </div>
            </div>
          </div>
        </div>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper tabCardWidget_wrapper">
              <div className="filters_card spaceBtnAlignCenter personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Space size="large">
                    <CustomMultiSelect
                      handleTreeSelect={handleOrg}
                      selectedId={selectedOrgId}
                      list={organizations}
                      label="Organizations"
                    />

                    <CustomSelect
                      label="Status"
                      value={connection}
                      setValue={(id: any) => {
                        activity.current = 'filter';
                        handelConnection && handelConnection(id);
                      }}
                      options={connectionData}
                      optionKey="value"
                      optionDisplay="name"
                      isAll
                    />
                  </Space>
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columnsData}
                  loading={loading}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename={total > 0 ? 'Tractor Healths' : 'Tractor Health'}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default TractorHealth;
