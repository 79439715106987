import moment from 'moment';
import {
  formatTeams,
  getDate,
  getDateTime,
  getDateTimes,
  getDateTimesByString,
  getFullDate,
  getOperationNewStatus,
  getTractorAvailableState,
  indicatorsOrder,
  tractorConstantsNew,
} from '../constants/Common';
import {
  DailyUsageReports,
  BatteryList,
  ChargePointsData,
} from '../constants/types';
import { mqttConstants } from '../util/MqttConstants';
import { getKeyValue } from '../components/admin/Common';
import { timeDuration } from '../components/dealer/common/common';

export const mapTractorsData = (data: any[]) =>
  data.map((element: any) => {
    let connectivity_level = 0;
    let network_type = [];
    const { heartbeat, basestation, battery, tractor_pin } = element;

    let hourMeter: any = Number(
      Math.floor(element.heartbeat?.hour_meter / 3600),
    );
    hourMeter = !isNaN(Number(hourMeter)) ? `${hourMeter} hours` : '-';

    element.hour_meter = heartbeat ? heartbeat.hour_meter && hourMeter : 0;

    element.softwareversion = heartbeat ? heartbeat?.software_version : '';
    element.firmware = heartbeat ? heartbeat?.firmware_version : '';

    element.basestation_id = basestation?.id || '-';
    element.basestation_name = basestation?.name || '-';

    element.battery_name = battery?.name || '-';
    element.basestation_pin = basestation?.bsin || '-';
    element.battery_pin = battery?.bin || '-';

    element.indicatorsData = [];
    element.isAvailable = getTractorAvailableState(
      element.connection,
      element?.heartbeat,
    );
    for (const arrangeIndicator of indicatorsOrder) {
      for (const indicator of element.indicators) {
        if (arrangeIndicator === indicator.indicator_name) {
          element.indicatorsData.push(indicator);
          if (indicator.indicator_key === 'Tractor_Communication')
            connectivity_level = indicator.indicator_level;
        }
      }
    }

    element.connectivity_level = connectivity_level;
    element.basestation_connected_status =
      basestation?.basestation_connected_status;
    network_type = heartbeat?.system_model?.split(',');

    if (network_type && network_type?.length >= 210) {
      element.active_network = network_type[209];
    }

    element.tractor_pin = ['0', '-1'].includes(tractor_pin)
      ? '-'
      : tractor_pin || '-';

    element.isTractorError =
      element?.indicators.filter(
        (indicator: any) => indicator.indicator_level === 3,
      ).length > 0;
    return element;
  });

export const mapBasestationData = (data: any[]) =>
  data?.map((element: any) => {
    const { bsin, name, basestation_connected_status: bs_con_status } = element;
    element.bsin = ['0', '-1'].includes(bsin) ? '-' : bsin || '-';
    element.name = name ?? '-';
    element.status = bs_con_status === 'online' ? 'available' : 'Off';
    element.software_version = element.current_software?.software_version;
    return element;
  });

export const mapEmployessData = (data: any[]) =>
  data.map((ele: any) => {
    const { teams = [] } = ele;
    ele.teamName = formatTeams(teams);
    ele.appAcess = ele?.teams?.some((team: any) => team?.app_access);
    return ele;
  });

export const mapReportsData = (
  data: DailyUsageReports[],
): DailyUsageReports[] =>
  data.map((record: DailyUsageReports) =>
    Object.assign({
      created_date_time: getDateTimes(record.created_date_time),
      date: `${getFullDate(record.start_date_str)} -
        ${getFullDate(record?.end_date_str)}`,
      name: record.report_title ? record.report_title : record.reports_name,
      id: record.id,
      reports_date: getDateTimesByString(record.report_date_str),
      reports_name: record.reports_name,
      download_report_url: record.download_report_url,
    }),
  );

export const mapBatteryData = (data: BatteryList[]): BatteryList[] =>
  data.map((element: any) => {
    const { bin, name, current_hour_meter_hours: hourMeter } = element;
    element.name = name ?? '-';
    element.bin = ['0', '-1'].includes(bin) ? '-' : bin || '-';
    element.hour_meter = hourMeter === '0' ? '-' : `${hourMeter} hours`;
    return element;
  });

export const mapChargingPointData = (
  data: ChargePointsData[],
): ChargePointsData[] =>
  data.map((record: ChargePointsData) => {
    const {
      charge_point_name,
      model,
      voltage,
      make,
      id,
      electricity_provider_plan_id,
    } = record;
    return Object.assign({
      charge_point_name,
      model,
      electricity_provider: electricity_provider_plan_id
        ? electricity_provider_plan_id?.provider
        : '-',
      voltage,
      make: make || '-',
      electricityId: record?.electricity_provider_plan_id?.id,
      id: id,
    });
  });

export const mapDiagnosticData = (data: any[]): any[] =>
  data.map((record: any) => {
    const {
      description = '',
      subsystem = '',
      functionality_affected = '',
      indicator = '',
      counter_measure = '',
    } = record.error_code_data ? record.error_code_data : {};
    const {
      id,
      tractor_id,
      error_code,
      created_date_time,
      indicator_level,
      modified_date_time,
    } = record;
    return Object.assign({
      id,
      start_time: created_date_time ? getDateTimes(created_date_time) : '-',
      error_code,
      severity: indicator_level,
      subsystem: subsystem || '-',
      description: description || '-',
      indicator_type: indicator || '-',
      created_date_time,
      modified_date_time: modified_date_time
        ? getDateTimes(modified_date_time)
        : '-',
      functionality_affected: functionality_affected ?? '',
      counter_measure: counter_measure ? counter_measure?.split(';') : '',
      tractor_id,
    });
  });

export const mapBasestationDiagnosticData = (data: any[]): any[] =>
  data.map((record: any) => {
    const { error_code_data, created_date_time } = record;
    record.created_date_time = created_date_time
      ? getDateTimes(created_date_time)
      : '-';
    record.subsystem = error_code_data?.subsystem;
    record.description = error_code_data?.description;
    return record;
  });

export const mapMaintenance = (data: any[]): any[] =>
  data.map((ele: any) => {
    ele.tractorName = ele.tractor ? ele.tractor?.name : '';
    ele.open_date_time = ele.open_date_time
      ? getDateTimes(ele.open_date_time)
      : '';
    ele.rulename = ele.name;
    return ele;
  });

export const mapTickets = (data: any[]): any[] =>
  data.map((ele: any) => {
    ele.completed_date_time = ele.completed_date_time
      ? getDateTimes(ele.completed_date_time)
      : '';
    ele.date_time = ele.created_date_time;
    ele.created_date_time = ele.created_date_time
      ? getDateTimes(ele.created_date_time)
      : '';
    ele.completed_by_user = ele.completed_by_user
      ? ele.completed_by_user.first_name + ' ' + ele.completed_by_user.last_name
      : '';
    ele.created_by_user = ele.created_by_user
      ? ele.created_by_user.first_name + ' ' + ele.created_by_user.last_name
      : '';
    ele.assignee = ele.assignee
      ? ele.assignee.first_name + ' ' + ele.assignee.last_name
      : '';
    ele.equipmentName = ele.equipment ? ele.equipment.name : 'Other';
    ele.ticket_level = ele.ticket_level.toString();
    ele.tractorId = ele.tractor_id;
    ele.ticketStatus = ele.status;
    ele.ticket_id = ele?.ticket_id || '-';
    return ele;
  });

export const mapImplementsData = (data: any[], tstate: any): any[] =>
  data.map((record: any) => {
    return {
      name: record.name,
      manufacturer: record.config_imm ? record.config_imm.manufacturer : '',
      model: record.config_imm ? record.config_imm.model : '',
      serial_number: record.serial_number,
      id: record.id,
      config_imm_uuid: record.config_imm_uuid,
      config_implements_uuid: record.config_implements_uuid,
      implement_type: record?.implement_type,
      is_autonomy_full_filled: record?.is_autonomy_full_filled,
      is_autonomy_implement: record?.is_autonomy_implement,
      showAutoDriveConnect: tstate?.showAutoDriveConnect,
      is_implement_info_missing: record?.is_implement_info_missing,
    };
  });

export const mapSchUpdatesData = (data: any[]) =>
  data?.map((record: any) => {
    return {
      name: record.name,
      softwareVersion: record?.fleet_health?.current_software_version || '-',
      firmWareVersion: record?.fleet_health?.current_firmware_version || '-',
      ota_status: record?.fleet_health ? record?.fleet_health?.ota_status : '-',
      id: record?.id,
      ticket_id: record?.fleet_health.ticket_id,
      serial_number: record?.serial_number,
      otaPercentage: record?.fleet_health?.ota_percentage,
      scheduleRemarks: record?.fleet_health?.scheduled_failed_remarks,
      otaDownloadStatus: record?.ota_download?.ota_download_availability_status,
      otaDownloadPercentage: record?.ota_download?.ota_download_percentage,
      ota_download_status: record?.ota_download?.ota_download_status,
      ota_estimated_time: record?.fleet_health?.ota_estimated_time,
      connection: record?.connection,
      indicators: record?.indicators || [],
      schDate: record?.fleet_health
        ? moment(record?.fleet_health?.scheduled_date_time).format(
            'ddd, MM/D [at] h:mm A',
          )
        : '-',
    };
  });

export const mapTractorUsageData = (data: any[], type = 'Daily'): any[] =>
  data.map((record: any) => ({
    date: type === 'Daily' ? record.date ?? '-' : record.month ?? '-',
    distance: record.distance || 0,
    manual_hours_num: record.manual_hours || '0',
    autonomy_num: record.autonomy_hours || '0',
    energy_used: record.energy_used || 0,
    co2_saved: record.co2_saved || 0,
    idle_hours_num: record.standby_hours || '0',
    human_detections: record.human_detections || 0,
    customer_name: record?.organization?.name ?? '',
    follow_me_hours_num: record.follow_me_hours || '0',
    vinerow_hours_num: record.vinerow_hours || '0',
    copycat_hours_num: record.copycat_hours || '0',
    driving_hours_num: record.driving_hours || '0',
    standby_hours_num: record.standby_hours || '0',
    remote_av_hours_num: record.remote_av_hours || '0',
    follow_me_hours:
      record.follow_me_hours_str === '0:00' ? '0' : record.follow_me_hours_str,
    vinerow_hours:
      record.vinerow_hours_str === '0:00' ? '0' : record.vinerow_hours_str,
    copycat_hours:
      record.copycat_hours_str === '0:00' ? '0' : record.copycat_hours_str,
    driving_hours:
      record.driving_hours_str === '0:00' ? '0' : record.driving_hours_str,
    standby_hours:
      record.standby_hours_str === '0:00' ? '0' : record.standby_hours_str,
    remote_av_hours:
      record.remote_av_hours_str === '0:00' ? '0' : record.remote_av_hours_str,
    manual_hours:
      record.manual_hours_str === '0:00' ? '0' : record.manual_hours_str,
    autonomy_hours:
      record.autonomy_hours_str === '0:00' ? '0' : record.autonomy_hours_str,
  }));

export const mapTractorUsageData1 = (data: any[], viewReport: string): any[] =>
  data.map((record: any) => ({
    tractorname: record.tractor?.name,
    clientNodeName: record.tractor.clientNodeName,
    date: viewReport === 'Daily' ? record.date ?? '-' : record.month ?? '-',
    distance: record.distance || 0,
    manual_hours: record.pm_manual_hours || '0',
    autonomy: record.pm_autonomy_hours || '0',
    energy_used: record.energy_used || 0,
    co2_saved: record.co2_saved || 0,
    idle_hours: record.pm_standby_hours || '0',
    human_detections: record.human_detections || 0,
    tractor_pin:
      record?.tractor?.tractor_pin === null
        ? '-'
        : record?.tractor?.tractor_pin,
    customer_name: record?.organization?.name ?? '',
    pm_follow_me_hours: record.pm_follow_me_hours || '0',
    pm_vinerow_hours: record.pm_vinerow_hours || '0',
    pm_copycat_hours: record.pm_copycat_hours || '0',
    pm_driving_hours: record.pm_driving_hours || '0',
    pm_standby_hours: record.pm_standby_hours || '0',
    pm_remote_av_hours: record.pm_remote_av_hours || '0',
  }));

export const mapNexusTractorsData = (data: any[]) =>
  data.map((d: any) => ({
    name: d.name,
    serial_number: d.serial_number,
    customer_organization_id: d.customer_organization?.id,
    customer_organization_name: d.customer_organization?.name,
    model_id: d.tractor_model?.id,
    model_name: d.tractor_model?.name,
    id: d.id,
    startTime: getDateTime(d.created_date_time),
    tractor_pin: d.tractor_pin ? d.tractor_pin : '-',
    installation_key: d.installation_key ? d.installation_key : '-',
    radio_channel_number:
      d?.radio_channel_number !== null ? d?.radio_channel_number : '',
    radio_id: d?.radio_id !== null ? d?.radio_id : '',
    ip_address: d.ip_address ? d.ip_address : '',
    n2_ip_address: d.n2_ip_address ? d?.n2_ip_address : '',
    voucher_uid: d?.voucher_uid ? d?.voucher_uid : '',
  }));

export const mapNexusBasestationData = (data: any[]) =>
  data.map((record: any) => ({
    name: record.name,
    serial_number: record.serial_number,
    model: record.basestation_model,
    Organization: record.customer_organization
      ? record.customer_organization.name
      : '-',
    baseId: record.id,
    startTime: getDateTime(record.created_date_time),
    bsin: record?.bsin ?? '-',
    radio_address: record?.radio_address !== null ? record?.radio_address : '-',
    radio_channel_number:
      record?.radio_channel_number !== null
        ? record?.radio_channel_number
        : '-',
    id: record?.id,
    mount_point_id: record?.mount_point_id,
    ntrip_server_id: record?.ntrip_server_id,
    installation_key: record?.installation_key,
    basestation_model: record?.basestation_model,
    radio_net_id: record?.radio_net_id,
    username: record?.mount_point?.username,
    mount_point_password: record?.mount_point?.password,
  }));

export const mapOrganizationData = (data: any[]) =>
  data.map((record: any) => ({
    id: record.id,
    orgId: record.org_uuid,
    orgName: record.name,
    startTime: getDateTime(record.created_date_time),
    adminEmail: record.admin_user_obj ? record.admin_user_obj.email : '-',
    is_email_verified: record.is_email_verified,
    is_resend_email_sent_status: record.is_resend_email_sent_status,
  }));

export const mapTractorMigrationData = (data: any[]) =>
  data.map((d: any, index: any) => ({
    name: d.name,
    serial_number: d.serial_number,
    organization: d.organization,
    migration: d.migration,
    customer_organization_id: d.organization?.id,
    customer_organization_name: d.organization?.name,
    model_id: d.tractor_model?.id,
    model_name: d.tractor_model,
    id: d.id,
    startTime: getDateTime(d.created_date_time),
    tractor_pin: d.tractor_pin ? d.tractor_pin : '-',
    installation_key: d.installation_key ? d.installation_key : '-',
    radio_channel_number:
      d?.radio_channel_number !== null ? d?.radio_channel_number : '',
    radio_id: d?.radio_id !== null ? d?.radio_id : '',
    ip_address: d.ip_address ? d.ip_address : '',
    n2_ip_address: d.n2_ip_address ? d?.n2_ip_address : '',
    voucher_uid: d?.voucher_uid ? d?.voucher_uid : '',
    connection: d.connection,
    remote_access_status: index % 2 === 0 ? 'Enabled' : 'Disabled',
    clientNodeName: d.client_node_name || '-',
    migrationStatus: d?.migration?.status,
  }));

export const mapNexusTicketsData = (data: any[]) =>
  data.map((ele: any) => {
    const startTime = ele.created_date_time ? ele.created_date_time : '';
    const endTime = ele.completed_date_time ? ele.completed_date_time : '';
    const duration = ele.completed_date_time
      ? timeDuration(new Date(endTime), new Date(startTime))
      : timeDuration(new Date(), new Date(startTime));
    const lastModified = ele.ticket_last_modified_date_time
      ? timeDuration(new Date(), new Date(ele.ticket_last_modified_date_time))
      : '';

    return {
      id: ele.id,
      date_time: ele.created_date_time,
      startTime: ele.created_date_time ? ele.created_date_time : '',
      endTime: ele.completed_date_time ? ele.completed_date_time : '',
      duration: ele.completed_date_time
        ? timeDuration(new Date(endTime), new Date(startTime))
        : timeDuration(new Date(), new Date(startTime)),
      durationHours: duration,

      lastModified: ele.ticket_last_modified_date_time
        ? timeDuration(new Date(), new Date(ele.ticket_last_modified_date_time))
        : '',
      lastInteractedWith: lastModified,
      completed_by_user: ele.completed_by_user
        ? `${ele.completed_by_user.first_name} ${ele.completed_by_user.last_name}`
        : '',
      created_by_user: ele.created_by_user
        ? `${ele.created_by_user.first_name} ${ele.created_by_user.last_name}`
        : '',
      equipmentName: ele?.equipment ? ele.equipment.name : 'Other',
      ticket_level: ele.ticket_level.toString(),
      ticket_priority: ele?.ticket_priority !== '' ? ele?.ticket_priority : '-',
      assignee: ele.assignee
        ? `${ele.assignee.first_name} ${ele.assignee.last_name}`
        : '-',
      groupId: ele?.group_id ? ele?.group_id?.id : '-',
      assigneeId: ele?.assignee_id ? ele?.assignee_id : '-',
      team: ele?.team ? ele?.team?.name : '-',
      organization: ele.organization ? ele.organization.name : '-',
      comments: ele.comments ? ele.comments : '',
      status: ele.status ?? '',
      tags: ele.meta_data && ele.meta_data?.Tags,
      ticketId: ele.ticket_id ? ele.ticket_id : '-',
      ticket_description: ele.ticket_description,
      ticket_last_modified_date_time: ele.ticket_last_modified_date_time,
    };
  });

export const mapNexusTractorHealthData = (data: any[], orgObj: any): any[] =>
  data.map((record: any) => {
    const heartbeatFirmware = record?.tractor_model_data?.split(',');
    const heartbeatFirmwareVersion =
      heartbeatFirmware?.length >= 122 && heartbeatFirmware[121];
    const downloadSpeedNumber = Number(
      Number(record.network_download_speed),
    ).toFixed(1);
    const uploadSpeedNumber = Number(
      Number(record.network_upload_speed),
    ).toFixed(1);
    const socNumber = Number(record.tractor_model?.soc?.split('.')[0]);
    const indicatorData: any[] = [];

    indicatorsOrder?.forEach((arrangeIndicator: any) => {
      record?.indicators?.forEach((indicator: any) => {
        if (arrangeIndicator === indicator?.indicator_name) {
          indicatorData?.push(indicator);
        }
      });
    });

    const last_online_time = record?.connection?.last_online_time
      ? getDateTime(record?.connection?.last_online_time)
      : '-';
    const record1 = {
      ...record,
      tractor_model: record.tractor_model_data,
    };
    return {
      ...record,
      id: record.id,
      jng:
        record?.tractor?.connection === 'online'
          ? getOperationNewStatus(record1)
          : tractorConstantsNew.off.key,
      isAvailable:
        record?.tractor?.connection === 'online'
          ? getOperationNewStatus(record1).toLowerCase()
          : tractorConstantsNew.off.key,
      lockout_details: orgObj?.lockout_details?.lockout_by ? 'Yes' : 'No',
      last_online_time: record?.connection?.last_online_time
        ? getDateTime(record?.connection?.last_online_time)
        : '-',
      last_offline_time: record?.connection?.last_offline_time
        ? getDateTime(record?.connection?.last_offline_time)
        : '-',
      org_id: record?.organization?.id,
      orgName: record?.organization?.name,
      daily_hour_meter: record.daily_hour_meter
        ? (Number(record.daily_hour_meter) / 3600).toFixed()
        : '-',
      offline_time:
        last_online_time !== '-'
          ? timeDuration(
              new Date(),
              new Date(record?.connection?.last_online_time),
            )
          : '-',
      tractor_hours:
        record?.hour_meter === 'None' || record?.hour_meter === '0'
          ? ' - '
          : (Number(record.hour_meter) / 3600).toFixed(),
      org_uuid: record?.orgnization?.org_uuid,
      soc:
        record.tractor_model?.soc && socNumber >= 0
          ? `${record.tractor_model?.soc?.split('.')[0]} %`
          : '-',
      name: record.tractor?.name,
      clientNodeName: record.tractor?.clientNodeName,
      tractor_pin: record.tractor.tractor_pin || '-',
      serial_number: record.tractor.serial_number,
      date_time: record.date_time ? getDateTime(record.date_time) : '-',
      drive_action_details:
        record?.tractor?.connection === 'online' && record.drive_action_details
          ? record.drive_action_details?.operator_firstname
          : '-',
      status: record.tractor?.connection ? record.tractor?.connection : '',
      firmware_version: heartbeatFirmwareVersion ?? '-',
      velocity:
        record?.tractor?.connection === 'online' &&
        record.localization?.velocity
          ? Number(
              (Number(record.localization?.velocity) * 2.237)
                .toFixed(1)
                .replaceAll('-', ''),
            )
          : '-',
      api_url: record?.organization?.api_url,
      active_network: getKeyValue(record, mqttConstants.ACTIVE_NETWORk, false),
      indicatorsData: indicatorData,

      uploadSpeed:
        record.network_upload_speed && Number(uploadSpeedNumber) >= 0
          ? `${Number(Number(record.network_upload_speed)).toFixed(1)} Mbps`
          : '-',

      downloadSpeed:
        record.network_download_speed && Number(downloadSpeedNumber) >= 0
          ? `${Number(Number(record.network_download_speed)).toFixed(1)} Mbps`
          : '-',
      radio_details: record?.radio_details,
      camera_calibration:
        record?.camera_calibration === true ? 'True' : 'False',
      tractor_model: record.tractor_model_data,
    };
  });

export const mapNexusBasestationHealthData = (data: any[]): any[] =>
  data.map((record: any) => ({
    ...record,
    basestation: record.basestation,
    connection: record.connection,
    organization: record.organization,
    jng: record.connection && record.connection.status,
    basestation_model: record.basestation?.basestation_model || '-',

    isAvailable:
      record.connection && record?.connection?.status === 'online'
        ? 'online'
        : 'offline',
    last_online_time: record?.connection?.last_online_time
      ? getDateTime(record?.connection?.last_online_time)
      : '-',
    last_offline_time: record?.connection?.last_offline_time
      ? getDateTime(record?.connection?.last_offline_time)
      : '-',
    org_id: record?.organization?.id,
    org_uuid: record?.organization?.org_uuid,
    orgName: record?.organization?.name,
    customerName: record?.organization?.name,
    date_time: record?.heartbeat?.epoch_ts
      ? getDateTime(record?.heartbeat?.epoch_ts)
      : '-',
    name: record.basestation?.name,
    model: record.basestation?.basestation_model || '-',
    serial_number: record.basestation.serial_number,
    status: record.connection && record.connection?.status,
    api_url: record?.organization?.api_url,
    active_network: record?.heartbeat?.system_status?.active_network,
    software_version: record?.heartbeat?.basestation_software_version ?? '-',
    router_status: record.basestation?.router_status ? 'Online' : 'Offline',
    sbc_status: record.basestation?.sbc_status ? 'Online' : 'Offline',
    ip_address: record.basestation?.ip_address,
    rtkConfigured: record?.heartbeat?.system_status?.radio_rtk_status,
    tractorsConnected: record?.heartbeat?.tractors_connected?.List,
  }));

export const mapNexusLogViewerData = (data: any[]): any[] =>
  data.map((record: any) => ({
    tractorName: record.tractor.client_node_name ?? '-',
    createTime: getDateTime(record.created_date_time),
    dataItems: record.parameters.data_index_names.toString(),
    startTime: record.parameters.start_date_time
      ? getDateTime(record.parameters.start_date_time)
      : '-',
    endTime: record.parameters.end_date_time
      ? getDateTime(record.parameters.end_date_time)
      : '-',
    status: record.status,
    remarks: record.remarks ? record.remarks : '-',
    actions: record.status,
    modelName: record.parameters.model_name,
    transaction_id: record.transaction_id,
    file_path: record.file_path,
    created_by: `${record.created_by?.first_name} ${record.created_by?.last_name}`,
    orgName: record.organization ? record.organization.name : '',
    csv_status: record?.csv_status,
    csv_file_path: record?.csv_file_path,
    csv_remarks: record?.csv_remarks ? record?.csv_remarks : '-',
    data_index_names: record?.parameters?.data_index_names,
    data_indexes: record?.parameters?.data_indexes,
  }));

export const mapNexusBaseLogViewerData = (data: any[]): any[] =>
  data.map((record: any) => ({
    tractorName: record.basestation.name ?? '-',
    createTime: getDateTime(record.created_date_time),
    dataItems: record.parameters.data_index_names.toString(),
    startTime: record.parameters.start_date_time
      ? getDateTime(record.parameters.start_date_time)
      : '-',
    endTime: record.parameters.end_date_time
      ? getDateTime(record.parameters.end_date_time)
      : '-',
    status: record.status,
    remarks: record.remarks ? record.remarks : '-',
    actions: record.status,
    modelName: record.parameters.model_name,
    transaction_id: record.transaction_id,
    file_path: record.file_path,
    created_by: `${record.created_by?.first_name} ${record.created_by?.last_name}`,
    orgName: record.organization ? record.organization.name : '',
    csv_status: record?.csv_status,
    csv_file_path: record?.csv_file_path,
    csv_remarks: record?.csv_remarks ? record?.csv_remarks : '-',
    data_index_names: record?.parameters?.data_index_names,
    data_indexes: record?.parameters?.data_indexes,
  }));

export const mapNexusCSATReportData = (data: any): any[] =>
  data.map((record: any) => ({
    customer_name: record?.organization?.name,
    report_date: record.report_date ? getDateTimes(record.report_date) : '',
    number_of_tractors: record.number_of_tractor,
    driving_hours: record.driving_hours,
    driving_hours_per_tractor: record.driving_hours_per_tractor,
    last_7_days: record.last_7_days,
    prior_7_days: record.prior_7_days,
    '3_weeks_ago': record['3_weeks_ago'],
    count_of_tractors: record.tractors_count_greater_than_30_mins,
    autodrive_hours: record.autonomy_hours,
    row_follow_hours: record.ADAS_row_follow_hours,
    routines_autodrive_shadow_hours: record.routines_autodrive_shadow_hours,
    idle_hours: record.idle_hours,
    manual_hours: record.manual_hours,
    level_1_tickets: record.level_1_tickets,
    level_2_tickets: record.level_2_tickets,
    level_3_tickets: record.level_3_tickets,
    fleet_utilization: record.fleet_utilization,
  }));

export const mapNexusOnDemandReportsData = (
  data: any[],
  state: any,
  report_type: string,
): any[] =>
  data.map((record: any) => ({
    createdDateTime:
      state && report_type === 'ondemand'
        ? `${getDateTime(record.created_date_time)}`
        : `${getDate(record.report_date_str)}`,
    reports_name: record.reports_name ?? '-',
    reports_status: record.reports_status ?? '-',
    id: record.id,
    reports_url: record.reports_url,
  }));

export const mapNexusReportsData = (data: any[]): any[] =>
  data.map((record: any) => ({
    id: record.id,
    name: record.name,
    frequency: record.frequency,
    emails: record.distribution_email_ids,
    action: record.distribution_email_ids,
    reportId: record.reports_id,
    report_type: record.report_type,
  }));

export const mapNexusTractorMigrationHistory = (data: any[]) =>
  data.map((record: any) => ({
    tractor_name: record.tractor_name,
    serialNumber: record.tractor_serial_number,
    created_date_time: getDateTime(record.created_date_time),
    status: record.status,
    source_organization: record.source_organization?.name || '',
    target_organization: record.target_organization?.name || '',
    id: record.id,
  }));

export const mapNexusServiceWorkData = (data: any[]): any[] =>
  data.map((ele: any) => ({
    completed_date_time: ele.completed_date_time
      ? getDateTimes(ele.completed_date_time)
      : '',
    created_date_time: ele.created_date_time
      ? getDateTimes(ele.created_date_time)
      : '',
    work_order_number: ele.work_order_number ? ele.work_order_number : '-',
    tractor_issue_description: ele.tractor_issue_description
      ? ele.tractor_issue_description
      : '-',
    equipmentName: ele?.equipment ? ele.equipment.name : '-',
    organizationName: ele.organization ? ele.organization.name : '-',
    status: ele.status ?? '',
    workOrderReportUrl: ele.work_order_report_url,
    ticketId: ele.ticket_id ? ele.ticket_id : '-',
    id: ele.id,
    work_order_labor_data: ele.work_order_labor_data,
    work_order_parts_data: ele.work_order_parts_data,
    tax_rate: ele.tax_rate,
    work_order_promised_date: ele.work_order_promised_date,
    qc_user_id: ele.qc_user_id?.id,
  }));
