import { notification } from 'antd';
import useOperatorAnalyticStore from '../store/operatorAnalyticStore';
import {
  getEfficiencyData,
  getOperatorHoursAndCoverage,
} from '../services/api';
import { useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import {
  getHoursAndMinutes,
  getOperationData,
  getTotalHours,
  getValue,
} from '../common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOperatorAnalytics = () => {
  const [completedWorkLoader, setCompletedWorkLoader] = useState(false);
  const [operationsLoader, setOperationsLoader] = useState(false);

  const {
    addCoverage,
    addOperationalHours,
    addCompletedWorkData,
    addOperationalHoursByOperator,
    addCoverageByOperator,
    addEfficiency,
    addTractorEfficiencyData,
    addIdleTime,
  } = useOperatorAnalyticStore();
  const { userDetails } = useContext(ApplicationContext);

  // const getValue = (item: any) => {
  //   return item ? item.replace(':', '.') : 0;
  // };

  const initCompletedWorkData = async (
    operationType = '',
    fromDate: string,
    toDate: string,
    operator_id: string,
  ) => {
    try {
      setCompletedWorkLoader(true);
      addCompletedWorkData([]);
      if (!userDetails?.organization) return false;
      const data = await getEfficiencyData(
        operationType,
        fromDate,
        toDate,
        operator_id,
      );
      const { completed_work } = data;
      const fdata = completed_work
        .sort((a: any, b: any) => b.date - a.date)
        .map((item: any) => ({
          date: Number(item.date),
          duration: item.duration,
          tractor: item.tractor,
          tractorId: item?.tractor?.id,
          tractorPin: item?.tractor?.tractor_pin,
          tractorShortPin: item?.tractor?.tractor_pin
            ? item?.tractor?.tractor_pin.slice(-5)
            : '',
          implement: item.implement_name,
          implementName: item.implement_name,
          coverage:
            item?.acres_covered && !isNaN(item?.acres_covered)
              ? item.acres_covered.toFixed(2)
              : 0,
          operators: item.operators,
          operation_type: item.operation_type,
          operator: item.operator_usage,
        }));
      addCompletedWorkData(fdata);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setCompletedWorkLoader(false);
    }
  };

  const initOperatorHoursAndCoverageData = async (
    from_date: number,
    to_date: number,
    operator_id: string,
  ) => {
    try {
      setOperationsLoader(true);
      const response = await getOperatorHoursAndCoverage(
        from_date,
        to_date,
        operator_id,
      );
      if (response?.op_hours_and_cov_for_imp) {
        const { op_hours_and_cov_for_imp } = response;

        const operationalHours = op_hours_and_cov_for_imp.map(
          (item: any, key: string) => {
            const label = Object.keys(item)[0];
            const dataValue = item[Object.keys(item)[0]];
            return Object.assign({
              category: label,
              total: getTotalHours(dataValue[0]?.total_operational_hours),
              value1: getValue(dataValue[0]?.manual_hours),
              value2: getValue(dataValue[0]?.operator_assist_hours),
              value3: getValue(dataValue[0]?.autodrive_hours),
              value4: getValue(dataValue[0]?.ideal_hours),
              time1: getTotalHours(dataValue[0]?.manual_hours),
              time2: getTotalHours(dataValue[0]?.operator_assist_hours),
              time3: getTotalHours(dataValue[0]?.autodrive_hours),
              time4: getTotalHours(dataValue[0]?.ideal_hours),
            });
          },
        );
        const coverage = op_hours_and_cov_for_imp.map(
          (item: any, key: string) => {
            const label = Object.keys(item)[0];
            const dataValue = item[Object.keys(item)[0]];
            return Object.assign({
              category: label,
              total: dataValue[0]?.total_covered_area_in_acre || 0,
              value1: dataValue[0]?.total_covered_area_in_acre || 0,
            });
          },
        );
        // Determine if all values are less than 1 (i.e., minutes)
        const allValuesAreMinutes = operationalHours.every((item: any) =>
          ['value1', 'value2', 'value3', 'value4'].every(
            (value) => item[value] < 1,
          ),
        );
        const processedData = getOperationData(
          operationalHours,
          allValuesAreMinutes,
        );
        addOperationalHoursByOperator(processedData);
        addCoverageByOperator(coverage);
      }
      if (response?.summary_op_hours_and_cov_for_imp) {
        const {
          combined_total_hours,
          combined_total_area,
          imp_efficiency,
          tractor_details = [],
          total_ideal_hours,
        } = response.summary_op_hours_and_cov_for_imp;

        const operations = {
          hours: combined_total_hours?.split(':')?.[0],
          minutes: combined_total_hours?.split(':')?.[1],
        };
        addOperationalHours(operations);
        addCoverage(combined_total_area);
        addEfficiency(imp_efficiency);
        addIdleTime({
          hours: total_ideal_hours?.split(':')?.[0],
          minutes: total_ideal_hours?.split(':')?.[1],
        });
        const tDetails = tractor_details.map((item: any) => {
          const [hours, mins] = item.total_operational_hours.split(':');
          return Number(hours * 60) + Number(mins);
        });
        const maxValue = Math.max(...tDetails);
        const tractorEfficiency = Array.isArray(tractor_details)
          ? tractor_details.map((tractor: any) => {
              const [hours, mins] = tractor.total_operational_hours.split(':');
              const percent = (
                ((Number(hours * 60) + Number(mins)) / maxValue) *
                100
              ).toFixed(2);
              return {
                tractorPin: tractor.serial_number,
                operationalHours: getHoursAndMinutes(
                  tractor.total_operational_hours,
                ),
                percent,
              };
            })
          : [];
        addTractorEfficiencyData([...tractorEfficiency]);
      }
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setOperationsLoader(false);
    }
  };

  return {
    operationsLoader,
    completedWorkLoader,
    initCompletedWorkData,
    initOperatorHoursAndCoverageData,
  };
};

export default useOperatorAnalytics;
