/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { saveVineRowsData, updateVineRowsData } from '../../constants/Api';
import { addingAltitude, makeChunkApiCalls } from '../../constants/Common';
import {
  VineRowPayloadSingle,
  VineRowPayloadTypesSingle,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  vineRowPayload: VineRowPayloadSingle;
}
export const VineRow: React.FC<Props> = ({
  showModal,
  handleCancel,
  vineRowPayload,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [vineRowData, setVineRowData] = useState<any>({
    head_a_latitude: vineRowPayload.vineRowXY[0].latitude,
    head_a_longitude: vineRowPayload.vineRowXY[0].longitude,
    head_b_latitude:
      vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1].latitude,
    head_b_longitude:
      vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1].longitude,
    head_a_altitude: vineRowPayload.head_a_altitude,
    head_b_altitude: vineRowPayload.head_b_altitude,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const altitudeHandler = async () => {
    if (
      vineRowPayload &&
      vineRowPayload.vineRowXY &&
      vineRowPayload.vineRowXY.length > 0
    ) {
      const vRD1 = {
        ...vineRowData,
        head_a_altitude: 0,
        head_b_altitude: 0,
      };
      setVineRowData(vRD1);

      const lat_long_vertices = await makeChunkApiCalls(
        vineRowPayload.vineRowXY,
      );
      if (lat_long_vertices && lat_long_vertices.length > 1) {
        const vRD = {
          ...vineRowData,
          head_a_altitude: lat_long_vertices[0].altitude,
          head_b_altitude: lat_long_vertices[1].altitude,
        };
        setVineRowData(vRD);
      }
    }
  };
  useEffect(() => {
    altitudeHandler();
  }, [vineRowPayload]);
  useEffect(() => {
    if (vineRowPayload) {
      if (vineRowPayload.name) {
        setName(vineRowPayload.name);
      }
    }
  }, [vineRowPayload]);
  const saveVineRows = async () => {
    setLoader(true);
    const payload: VineRowPayloadTypesSingle = {
      name: name.trim(),
      head_a_latitude: vineRowPayload.vineRowXY[0].latitude,
      head_a_longitude: vineRowPayload.vineRowXY[0].longitude,
      head_b_latitude:
        vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1].latitude,
      head_b_longitude:
        vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1].longitude,
      head_a_altitude: vineRowPayload.head_a_altitude,
      head_b_altitude: vineRowPayload.head_b_altitude,
      ...vineRowData,
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      if (vineRowPayload.action === 'ADD') {
        const response = await saveVineRowsData(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        handleCancel(true);
        notification.success({
          message: response.msg,
        });
      }
      if (vineRowPayload.action === 'EDIT') {
        const response = await updateVineRowsData(
          organization.api_url,
          organization.farm.id,
          vineRowPayload.vineRowId,
          payload,
        );
        handleCancel(true);
        notification.success({
          message: response.msg,
        });
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const setVineRowDataHandler = (value: number, type: string) => {
    const item = vineRowData;
    item[type] = value;
    setVineRowData(item);
  };

  const swapAAndB = () => {
    const {
      head_a_latitude,
      head_a_longitude,
      head_a_altitude,
      head_b_latitude,
      head_b_longitude,
      head_b_altitude,
    } = vineRowData;
    const payload = {
      head_a_latitude: head_b_latitude,
      head_a_longitude: head_b_longitude,
      head_a_altitude: head_b_altitude,
      head_b_latitude: head_a_latitude,
      head_b_longitude: head_a_longitude,
      head_b_altitude: head_a_altitude,
    };
    setVineRowData(payload);
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  return (
    <Modal
      className="commonPopup addPolygon"
      title={t(translate.map.crop_row)}
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        <div className="formCol dfright">
          <label className="formLabel"> </label>
          <button
            className="btnSave swapBtn"
            onClick={() => swapAAndB()}
            style={{ float: 'right' }}
          >
            Swap Head A & B
          </button>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">Head A</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">Head B</label>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Latitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_a_latitude')
            }
            defaultValue={vineRowPayload.vineRowXY[0].latitude}
            value={vineRowData.head_a_latitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Latitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_b_latitude')
            }
            defaultValue={
              vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1]
                .latitude
            }
            value={vineRowData.head_b_latitude}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Longitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_a_longitude')
            }
            defaultValue={vineRowPayload.vineRowXY[0].longitude}
            value={vineRowData.head_a_longitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Longitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_b_longitude')
            }
            defaultValue={
              vineRowPayload.vineRowXY[vineRowPayload.vineRowXY.length - 1]
                .longitude
            }
            value={vineRowData.head_b_longitude}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Altitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_a_altitude')
            }
            defaultValue={vineRowPayload.head_a_altitude}
            value={vineRowData.head_a_altitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Altitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_b_altitude')
            }
            defaultValue={vineRowPayload.head_b_altitude}
            value={vineRowData.head_b_altitude}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default VineRow;
