/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

interface Props {
  label?: any;
  value?: any;
  cssClass?: any;
  setValue?: (data: any) => void;
  options?: any;
  optionKey?: any;
  optionDisplay?: any;
  jsonFormat?: boolean;
  required?: any;
  validations?: any;
  testId?: any;
  isAll?: boolean;
  className?: string;
  ketValue?: any;
  showSearch?: boolean;
}

const CustomSelect: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay = 'name',
  jsonFormat,
  required,
  validations,
  testId,
  isAll,
  className,
  ketValue,
  showSearch,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '' && !isAll) return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className={`float-label ${className}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        data-testid={testId}
        style={{ width: '100%' }}
        value={value}
        onSelect={(e: any) => {
          setValue && setValue(e);
        }}
        showSearch={!!showSearch}
        optionFilterProp="children"
        className={
          !validations && required
            ? classNames({
                errorClass: !validations && required,
              })
            : cssClass
        }
      >
        {isAll && <option value="">All</option>}
        {options &&
          options?.length > 0 &&
          options?.map((data: any) => {
            return (
              <option
                value={jsonFormat ? JSON.stringify(data) : data[optionKey]}
                key={data[optionKey]}
                data-testid={`${
                  optionDisplay ? data[optionDisplay] : data[optionKey]
                }-CustomSelect`}
                disabled={data && data.disabled ? true : false}
              >
                {optionDisplay ? data[optionDisplay] : data[optionKey]}
                {ketValue || ''}
              </option>
            );
          })}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomSelect;
