import React, { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { Switch } from 'react-router';

import HelpCenter from '.';
import DefaultView from './DefaultView';
import SearchedResult1 from './SearchedResult1';

import HelpCenterProvider, { useHelpContext } from './HelpCenterContext';

import PrivateRoute from '../../routes/PrivateRoute';
import RoutesConstants from '../../routes/RoutesConstant';
import { ApplicationContext } from '../../context/AppContext';
import { getCategories } from './services/API';
import AppLoader from '../common/AppLoader';
import '../HelpCenter/styles.css';
import { useLocation } from 'react-router-dom';
import { track } from '../../util/logger';

const HelpCenterSearchContent: React.FC = () => {
  const { HCReducer } = useHelpContext();
  const { userDetails } = useContext(ApplicationContext);
  const [, dispatch] = HCReducer;
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === RoutesConstants.HelpCenterSearch) {
      track('Help Center default View', {
        event: `Help Center default view`,
      });
    } else if (location.pathname === RoutesConstants.HelpCenter) {
      track('Help Center Detail View', {
        event: `Help Center Detail view`,
      });
    } else if (location.pathname === RoutesConstants.HelpCenterResult) {
      track('Help Center Search View', {
        event: `Help Center Search view`,
      });
    } else if (location.pathname === RoutesConstants.HelpCenterMedia) {
      track('Help Center Media View', {
        event: `Help Center Media view`,
      });
    }
  }, [location]);

  const fetchCategories = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getCategories(api_url, 1);
      const data = Array.isArray(result?.data) ? result?.data : [];
      dispatch({
        type: 'CATEGORIES',
        payload: data,
      });
      if (data?.length)
        dispatch({
          type: 'CATEGORY',
          payload: data[0],
        });
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization) fetchCategories();
  }, [userDetails]);

  return (
    <Switch>
      <PrivateRoute
        exact
        path={RoutesConstants.HelpCenterSearch}
        component={DefaultView}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.HelpCenterResult}
        component={SearchedResult1}
      />
      <PrivateRoute
        exact={false}
        path={RoutesConstants.HelpCenter}
        component={HelpCenter}
      />
      <AppLoader loader={loader} />
    </Switch>
  );
};

const HelpCenterSearch: React.FC = () => (
  <HelpCenterProvider>
    <HelpCenterSearchContent />
  </HelpCenterProvider>
);

export default HelpCenterSearch;
