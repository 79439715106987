/* eslint-disable no-console */
import { notification } from 'antd';
import moment from 'moment';
import { Feature, Overlay, View } from 'ol';
import OLMap from 'ol/Map';
import { ScaleLine } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  getOperationalDriveActionPath,
  getPolygonsData,
} from '../../../constants/Api';
import {
  calPtsDistance,
  displayRecenterAllViewPortSetUp,
  drawPoly,
  getDateTime,
  getPathBorderColor,
  getPathColor,
  getPathInfo,
  polygonHoverLabel,
  priority,
} from '../../../constants/Common';
import { rangeForAnalytics } from '../../../constants/constant';
import { MapFeature } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import { getOperations } from '../services/api';
import useStore from '../store/operationAnalytics';
import tractorAnalyticDetailsStore from '../store/tractorAnalyticDetails';
import autoDrive from './../images/path-8134-1.svg';
import operatorAssistant from './../images/path-8134-2.svg';
import idle from './../images/path-8134-3.svg';
import manualDrive from './../images/path-8134.svg';

import { MapSkeleton } from '../../common/Skeleton';
import { renderOperationText } from '../common';
import './styles.scss';

let gSelectedOperationType = '';
interface Props {
  operationType?: string;
  operatorId?: string;
  onClick?: (operator: string, data?: any) => void;
}

const OperationalCoverageMap: React.FC<Props> = ({
  operationType,
  operatorId,
  onClick,
}: Props) => {
  const { userDetails, APPReducer, user } = useContext(ApplicationContext);
  const [appState, appDispatch] = APPReducer;
  const { polygonsList, groundZero, tractorsMap } = appState;
  const {
    dateRange,
    setSelectedDate,
    view,
    setView,
    setFromView,
    driveActionsList,
    setDriveActionsList,
  } = useStore();
  const { selectTractor } = tractorAnalyticDetailsStore();

  const [mapInitialized, setMapInitialized] = useState(false);
  const [toggleSatellite, setToggleSatellite] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [operations, setOperations] = useState([]);
  const [operationTypes, setOperationTypes] = useState<string[]>([]);
  const [selectedOperationType, setSelectedOperationType] =
    useState<string>('');
  const [fromDate, toDate] = dateRange;

  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const tractorPathOpacityLowLayerRef = useRef<VectorLayer<any>>();
  const tractorPathOpacityLayerRef = useRef<VectorLayer<any>>();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const timerRef = useRef(null);
  const initOperations = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (user && groundZero) {
      initializeMap();
    }
  }, [user, groundZero]);

  useEffect(() => {
    if (groundZero) {
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);
    }
  }, [groundZero]);

  //  mapInitialization start here
  const initializeMap = async () => {
    // setIsLoading(false);
    try {
      const { latitude, longitude } = groundZero;

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: true,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: false,
      });
      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: 0,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const tractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });
      const tractorPathFeaturesLayerOpacity = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH + 1,
      });
      const tractorPathFeaturesLayerOpacityLow = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
        opacity: 0.5,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialPathLayer,
          polygonsFeaturesLayer,
          tractorPathFeaturesLayer,
          tractorPathFeaturesLayerOpacity,
          tractorPathFeaturesLayerOpacityLow,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857'),
          zoom: 2,
          maxZoom: 25,
        }),
      });

      // set flag for map initialization
      setMapInitialized(true);
      // centerTheLocation([longitude, latitude], initialMap, 15);
      mapRef.current = initialMap;
      // initialize safelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      polygonsLayerRef.current = polygonsFeaturesLayer;

      tractorPathLayerRef.current = tractorPathFeaturesLayer;
      tractorPathOpacityLayerRef.current = tractorPathFeaturesLayerOpacity;
      tractorPathOpacityLowLayerRef.current =
        tractorPathFeaturesLayerOpacityLow;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here
  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  // useEffect(() => {
  //   vectorTileLayerRef.current?.setVisible(!toggleSatellite);
  //   satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  // }, [toggleSatellite]);

  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      const listItems: MapFeature[] = [];
      records.map(async (record: any, index: number) => {
        const { polygonFeature, polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }
        if (record.color === 'brown') {
          polygonFeature &&
            displayRecenterAllViewPortSetUp(
              [polygonFeature],
              mapRef.current,
              80,
            );
        }
        // polygons added to maintain app context start
        listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });
      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    userDetails?.organization && dateRange && loadOperations();
  }, [userDetails, dateRange, operatorId]);

  const loadOperations = async () => {
    try {
      let resp = await getOperations(
        userDetails.organization.api_url,
        moment(fromDate).startOf('day').toDate().getTime(),
        // 1668837614309,
        moment(toDate).endOf('day').toDate().getTime(),
        // 1712550671224,
        operationType,
        operatorId,
      );
      // filter zero distance JAGs
      resp = Object.keys(resp)
        .sort((a, b) => a.localeCompare(b))
        .reduce((a: any, key: string) => {
          let item = resp[key];
          item = item.filter((jng: any) => !!jng.distance);
          a[key] = item;
          return a;
        }, {});
      setOperations(resp);
      initOperations.current = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        tractorPathLayerRef?.current?.getSource()?.clear();
        const types: string[] = Object.keys(operations);
        setOperationTypes(types);
        const data: any = operations;
        const returnMap = Object.keys(operations).map((keyItem: any) => {
          const listItem: any = data[keyItem];
          const promises = listItem.map(async (jng: any) => {
            await getAllPoints(jng, keyItem);
          });
          return Promise.all(promises);
        });
        await Promise.allSettled(returnMap);
        setIsLoading(false);
        Object.keys(data).length === 0 && setIsLoading(false);
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setIsLoading(false);
        setDriveActionsList(driveActionsList);
      }
    };
    if (initOperations.current) init();
  }, [operations]);

  const getAllPoints = async (jumpAndGo: any, keyItem: string) => {
    try {
      const { drive_action_uuid: selectedDriveAction, tractor } = jumpAndGo;
      if (
        userDetails &&
        userDetails.organization &&
        selectedDriveAction &&
        selectedDriveAction.length > 0
      ) {
        const { organization } = userDetails;
        const response =
          driveActionsList && driveActionsList.get(selectedDriveAction)
            ? driveActionsList.get(selectedDriveAction)
            : await getOperationalDriveActionPath(
                organization.api_url,
                selectedDriveAction,
              );
        !driveActionsList.get(selectedDriveAction) &&
          driveActionsList.set(selectedDriveAction, response);
        response['jng'] = jumpAndGo;
        response['tractor_name'] = tractor?.name;
        drawJAG(selectedDriveAction, response, true, keyItem);
      }
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };
  const drawJAG = (
    id: string,
    jumpAndGo: any,
    all = false,
    keyItem: string,
  ) => {
    const {
      tractor_id: tractorId,
      records: points,
      tractor_name = '',
      operator_name = '',
      jng,
    } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastIndexPoint: any = null;
    points
      .filter(
        (point: any) =>
          point.planner !== 'standby' && point.planner !== 'copycat',
      ) // filter standby
      .map((point: any) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            path_pt: {
              longitude: number;
              latitude: number;
              created_date_time: number;
              dTime: string;
            },
            i: number,
          ) => {
            const ar = point.path;

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1] ? calPtsDistance([path_pt, ar[i + 1]]) : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );

        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            item.map(
              (path_pt: {
                longitude: number;
                latitude: number;
                created_date_time: number;
                dTime: string;
              }) => {
                path_pt.dTime = getDateTime(path_pt.created_date_time);
                arr?.push(
                  proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                );
              },
            );

            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer?.getSource().getFeatureById(id);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];
            tractorLocation.setGeometry(new LineString(arr));
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];

            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('hoverStyle', pathHover);
            // tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('name', '');
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('operationType', keyItem);

            tractorLocation.set('drawPointsText', point);
            tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', jng);
            tractorLocation.set('operationInfo', jumpAndGo);

            defaultLayer?.getSource().addFeature(tractorLocation);
          });
      });

    if (!all && oarr && oarr.length > 0 && mapRef.current) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        mapRef.current?.getView().setCenter(oarr[0]);
        mapRef.current?.getView().setZoom(17);
      }
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);

  const handleClick = () => {
    let click = false;
    mapRef.current?.on('click', (evt) => {
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const feat = feature.get('features');
        if (feat && feat.length > 1) {
          const view = mapRef.current?.getView();
          if (view) {
            const zoom = view.getZoom();
            if (zoom && zoom < 18) {
              view.setZoom(18);
            }
          }
        }
        const pointType = feature.get('pointType');
        if (pointType && pointType === 'PATH') {
          const oType = feature.get('operationType');
          if (
            gSelectedOperationType !== '' &&
            oType !== gSelectedOperationType
          ) {
            return;
          }
          if (!click) {
            click = true;
            const jng = feature.get('jng');
            // const operationType = feature.get('operationType');
            setSelectedDate(moment(jng.drive_action_start_date_time));
            if (onClick) {
              onClick(oType);
            }
            // console.log('jng', jng);
            selectTractor({
              implement: jng.implement_name,
              implementName: jng.implement_name,
              tractorId: jng?.tractor_id,
              tractorPin: jng?.tractor?.tractor_pin || '',
              tractorShortPin: jng?.tractor?.tractor_pin?.slice(-5),
            });
            setFromView(view);
            setView('tractor');
            setTimeout(() => {
              click = false;
            }, 100);
          }
        }
      });
    });
  };
  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (pointType === 'PATH') {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              selected = feature;

              selected?.setStyle(feature.get('hoverStyle'));
              const jng = selected.get('jng');
              const operationType = feature.get('operationType');

              const innerHTML = getPathInfo(jng, operationType as string);
              selected.set('name', innerHTML);
              content.current.innerHTML = innerHTML;
              if (
                !overlay.getElement()?.classList.contains('tooltip-container')
              ) {
                overlay.getElement()?.classList.add('tooltip-container');
              }
              overlay.setPosition(evt.coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
          }
          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (dataType !== 'PATH') {
              overlay.getElement()?.classList.remove('ol-details-popup');
            }
            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        overlay.setPosition(undefined);
        mapRef.current?.addOverlay(overlay);
        evt.map.getTargetElement().style.cursor = '';
      }
    });
  };

  useEffect(() => {
    gSelectedOperationType = selectedOperationType;
    tractorPathOpacityLayerRef?.current?.getSource().clear();
    tractorPathOpacityLowLayerRef?.current?.getSource().clear();
    if (selectedOperationType !== '') {
      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          feature.get('operationType') !== selectedOperationType &&
            tractorPathOpacityLowLayerRef.current
              ?.getSource()
              .addFeature(feature);

          feature.get('operationType') === selectedOperationType &&
            tractorPathOpacityLayerRef.current?.getSource().addFeature(feature);
        });
      tractorPathLayerRef?.current?.setVisible(false);
    } else {
      tractorPathLayerRef?.current?.setVisible(true);
    }
  }, [selectedOperationType]);

  return (
    <>
      <div className="coverage-map">
        <div
          ref={mapElement}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            borderRadius: 8,
          }}
          //to align right bottom of map distance
          className="posHd"
        >
          {isLoading && <MapSkeleton />}
          <div className="image-enhancer">
            <div className="header3">
              <div className="operations8">
                <span>Operational Coverage</span>
                <span className="wloader-small">
                  <div className={isLoading ? 'wave-loader-small' : 'hide'}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </span>
              </div>
              <div className="map-feature-op-type-filter">
                {!operationType &&
                  operationTypes.map((listItem: string, i: number) => (
                    <>
                      <div
                        key={i}
                        className={`filters ${
                          selectedOperationType === listItem ? 'sel' : ''
                        }`}
                        onClick={() => {
                          if (selectedOperationType === listItem) {
                            setSelectedOperationType('');
                          } else {
                            setSelectedOperationType(listItem);
                          }
                        }}
                      >
                        <div className="mowing1">
                          {renderOperationText(listItem)}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className="legend">
            <div className="autonomous">Key</div>
            <div className="version-vitality">
              <div className="history-hero">
                <img
                  className="path-8134-icon"
                  loading="lazy"
                  alt=""
                  src={manualDrive}
                />

                <div className="autonomous1">Manual Drive</div>
              </div>
              <div className="history-hero1">
                <img className="path-8134-icon1" alt="" src={autoDrive} />

                <div className="autonomous">Autodrive</div>
              </div>
              <div className="history-hero2">
                <img
                  className="path-8134-icon2"
                  alt=""
                  src={operatorAssistant}
                />

                <div className="autonomous">Operator Assist</div>
              </div>
              <div className="history-hero3">
                <img className="path-8134-icon3" alt="" src={idle} />

                <div className="autonomous">Idle</div>
              </div>
            </div>
          </div>
        </div>
        {mapInitialized && (
          <div
            ref={container}
            className="ol-popup"
            style={{ display: 'block' }}
          >
            <div ref={content}></div>
          </div>
        )}
        {/* <OperationLoader loader={isLoading} /> */}
      </div>
    </>
  );
};

export default OperationalCoverageMap;
