import React from 'react';
import { CommonProgressBar } from './CommonProgressBar';
import schVerified from '../../assets/images/schVerified.svg';

interface Props {
  filterData: any;
}

export const UpToDateContainer: React.FC<Props> = ({ filterData }) => {
  const upToDate = () =>
    filterData.filter((record: any) => record?.ota_status === 'up_to_date');

  return (
    <div className="mainContainers1">
      <div className="tractorContainer padding1620">
        <div className="outOfTxt">Up To Date</div>
        <div className="tractorsLength">{upToDate().length}</div>
      </div>
      <div className="outBoxContainer">
        {upToDate().length > 0 ? (
          <>
            {upToDate().map((d: any) => (
              <div className="installationsCon" key={d.id}>
                <div className="tractorContainer">
                  <div className="numberTxt">{d?.name}</div>
                  <img src={schVerified} alt="" />
                </div>
                <div className="swUpdateTxt">Software {d?.softwareVersion}</div>
                <div className="swUpdateTxt">Firmware {d?.firmWareVersion}</div>
              </div>
              // <div className="upToDateBox" key={d.id}>
              //   <div className="mb8">
              //     <div className="tractorContainer">
              //       <div className="numberTxt">{d?.name}</div>
              //       <img src={schVerified} alt="" />
              //     </div>
              //     <CommonProgressBar data={d} install estTime />
              //   </div>
              // </div>
            ))}
          </>
        ) : (
          <div className="outOfBoxContent">
            Monitor your tractors install progress here
          </div>
        )}
      </div>
    </div>
  );
};
